@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';

.my-surprise-order {
  margin-top: spacing.$s10;
  border-top: 1px solid var(--theme-color-base);
  padding-top: spacing.$s10;

  &:first-of-type {
    border-top: 0;
    padding-top: 0;
  }

  &__delivery-date {
    font-size: font.$size-base;
    font-family: font.$primary;
    font-weight: font.$weight-regular;
    display: block;
    margin-top: spacing.$s2;

    @media screen and (min-width: breakpoint.$lg) {
      display: inline-block;
      margin-left: spacing.$s2;
      margin-top: 0;
    }
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: color.$base-80;
      line-height: 21px;
    }
  }

  &__item {
    margin-top: spacing.$s10;

    &__content {
      display: flex;

      .orderPreviewWrapper {
        width: 120px;
        position: relative;
        cursor: pointer;

        .searchIcon {
          position: absolute;
          background-color: var(--theme-color-white);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
          opacity: 0;
          visibility: hidden;
          transition: transition.$default opacity;
        }

        &:hover .searchIcon {
          opacity: 0.8;
          visibility: visible;
        }
      }

      .orderDetails {
        margin-left: spacing.$s2;
        flex-grow: 1;

        @media screen and (min-width: breakpoint.$lg) {
          margin-left: spacing.$s10;
        }

        h4 {
          font-weight: font.$weight-600;
          font-family: font.$primary;
          font-size: font.$size-base;
        }

        .checkPreviewButton {
          margin-top: 5px;
          text-decoration: underline;
        }

        .noExample {
          display: inline-block;
          margin-top: 5px;
          font-style: italic;
        }
      }

      .orderDetailsExtra {
        color: color.$base-80;
        line-height: 21px;
        margin-top: spacing.$s2;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: breakpoint.$lg) {
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
        }
      }
    }
  }

  &__summary {
    margin-top: spacing.$s10;
    border-top: 1px solid var(--theme-color-base);
    padding-top: spacing.$s6;

    .summaryRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: spacing.$s2 0;

      &:last-child {
        border-top: 1px solid var(--theme-color-base);
        color: var(--theme-font-color);
        margin-top: spacing.$s6;
        padding: spacing.$s10 0 0;
        font-size: 16px;
      }
    }
  }
}
