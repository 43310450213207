@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';

.form-label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, -50%);
  top: 50%;
  left: spacing.$s4;
  transition: transition.$default all;

  &-optional {
    font-size: 12px;
    color: var(--theme-font-color-lighter);
  }

  &--alone {
    margin-bottom: spacing.$s2;
  }
}

// Focus state
.form-input {
  &:focus ~ .form-label,
  &.dirty ~ .form-label {
    top: calc(50% - 9px);
    font-size: 12px;
    color: var(--theme-font-color-lighter);
    left: 17px;
  }
}

// Checkbox label
.form-checkbox + .form-label,
.form-radio + .form-label {
  position: static;
  left: 0;
  top: 0;
  transform: translate(0);
  pointer-events: all;
  user-select: none;
  padding-top: 1px;
  padding-left: spacing.$s4;
  cursor: pointer;

  .newsletter & {
    font-size: 13px;
    color: var(--theme-font-color-lighter);
  }
}

// Textarea label
.form-textarea {
  & + .form-label {
    top: spacing.$s4;
    transform: translate(0, 0);
  }

  &:focus ~ .form-label,
  &.dirty ~ .form-label {
    top: spacing.$s4;
  }
}

// Required label
.form-input:required {
  & ~ .form-label:after {
    content: '*';
    color: var(--theme-color-error);
    margin-left: 2px;
  }
}
