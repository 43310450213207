@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';

.cookie-notification {
  color: var(--theme-font-color);

  overflow: hidden;
  border: 0;
  padding: 0;
  border-radius: var(--theme-border-radius-md);
  outline: none;

  &::backdrop {
    background: color.$base-40;
  }

  &__content {
    border-radius: var(--theme-border-radius-md);
    display: flex;
    flex-direction: column;

    p {
      white-space: pre-wrap;
      text-align: start;
    }

    @media screen and (min-width: breakpoint.$md) {
      margin: 0;
      max-width: 480px;
    }

    a, .consent-link {
      display: inline;
      cursor: pointer;
      text-decoration: underline;
      color: var(--theme-color-secondary);
    }
  }
}
