@use '../../variable/breakpoint';
@use '../../../stories/scss/settings/spacing';

.form-row {
  margin-top: spacing.$s4;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    .form-input {
      margin-bottom: 1px; // for when you end your section with a form row
    }
  }

  &--multi {
    display: flex;
    column-gap: spacing.$s2;
    justify-content: space-between;
  }

  &--flex {
    display: flex;

    & > * {
      margin-left: spacing.$s4;
      flex: 0 0 auto;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &-wrap {
      flex-wrap: wrap;
      display: flex;

      .form-row {
        margin-top: spacing.$s6;
        flex-basis: 100%;
      }
    }
  }

  &--end {
    justify-content: flex-end;
  }

  &--split {
    & > * {
      margin-top: spacing.$s4;
    }
  }

  @media screen and (min-width: breakpoint.$md) {
    &--split {
      display: flex;

      & > .form-row {
        margin-top: 0;
      }

      & > * {
        width: 100%;
        flex: 0 1 auto;
        margin-left: spacing.$s4;
        margin-top: 0;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  // MySurprise
  & + .has-border-top {
    margin-top: spacing.$s10;
    padding-top: spacing.$s10;
  }

  // Checkout
  &.util__collapsible--collapsed {
    margin-top: 0;
  }
}
