.image-aspect-equal {
  aspect-ratio: 1;
}

.stretch-image {
  img {
    width: 100%;
    height: 100%;
    border-radius: var(--theme-border-radius-sm);
  }
}

.fill-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video,
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.object-fit-cover {
  img {
    object-fit: cover;
  }
}
