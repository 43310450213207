@use '../../variable/breakpoint';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/color';

.newsletter {
  width: 100%;

  .content & {
    margin-top: spacing.$s6;

    @media screen and (min-width: breakpoint.$md) {
      margin-top: spacing.$s10;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;

    &-control {
      flex: 0 1 100%;
      margin-bottom: spacing.$s4;

      @media screen and (min-width: breakpoint.$md) {
        margin-bottom: 0;
      }
    }

    &-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: spacing.$s6;

      &--md {
        margin-top: spacing.$s10;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &:empty {
        margin-top: 0;
        display: none;
      }

      @media screen and (min-width: breakpoint.$md) {
        flex-direction: row;
      }
    }
  }

  .textColor {
    &--error {
      margin-left: spacing.$s2;
      margin-top: spacing.$s4;
      color: var(--theme-color-error);
    }
  }

  &-button {
    @media screen and (min-width: breakpoint.$md) {
      margin-left: spacing.$s2;
      height: 48px;
    }

    span {
      white-space: nowrap;
    }
  }
}
