@use '../variable/z-index';

// Z-index order of the website
.notification-bar,
.discount-campaign,
.PickupPointsMap__AddressSearch,
.PickupPointsMap__Notifications,
.PickupPointList .slider button {
  z-index: z-index.$low-1;
}

.DefaultHandle_handle,
.hero__overlay {
  z-index: z-index.$low-2;
}

.hero > a {
  z-index: z-index.$low-3;
}

.gift-help,
.ps-slider-container,
.cm-button,
.hero__content,
.product-information__sticky-cta {
  z-index: z-index.$low-4;
}

.menu__backdrop,
.breadcrumbs,
.error-mount {
  z-index: z-index.$low-5;
}

// Needs to be above FAQ categories on FAQ because CX chat
.menu,
.main-nav__dropdown--first,
.header.header {
  z-index: z-index.$top-1;
}

.main-nav__header,
.yspStickyContainer {
  z-index: z-index.$top-2;
}

.personalisation-modal {
  z-index: z-index.$top-3;
}

.overlay, .account-menu {
  z-index: z-index.$top-4;
}

.drawer {
  z-index: z-index.$top-5;
}

// We need to make sure the cookie banner is above everything else
.cookie-notification {
  z-index: z-index.$highest;
}
