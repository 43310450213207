@use '../../variable/breakpoint';

.slide-scroll {
  &--start,
  &--end {
    display: none;
  }

  @media screen and (min-width: breakpoint.$lg) {
    position: relative;

    &--start,
    &--end {
      top: 0;
      bottom: 0;
      margin: auto 0;
      position: absolute;
      z-index: 4;
      display: flex;
      opacity: 0;
      pointer-events: none;
    }

    &--start {
      left: -1px;
    }

    &--end {
      right: -1px;
    }
  }
}
