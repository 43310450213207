.c {
  &-primary {
    color: var(--theme-color-primary);
  }

  &-secondary {
    color: var(--theme-color-secondary);
  }

  &-text {
    &--light {
      color: var(--theme-font-color-light);
    }
    &--lighter {
      color: var(--theme-font-color-lighter);
    }
  }
}
