@use '../../variable/breakpoint';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';
@use '../../mixin/container';

.breadcrumbs {
  padding: spacing.$s4 spacing.$s2;

  @media screen and (min-width: breakpoint.$md) {
    padding: spacing.$s4 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  ul {
    display: flex;

    @media screen and (max-width: breakpoint.$md) {
      @include container.h-scrollable();
    }

    @media screen and (min-width: breakpoint.$md) {
      overflow: initial;
    }

    li {
      font-size: font.$size-xxs;
      display: flex;
      padding-top: 2px;

      &:before {
        content: '/';
        margin: 0 spacing.$s2;
        color: var(--theme-font-color-lighter);
      }

      &:first-child:before {
        display: none;
      }

      span {
        white-space: nowrap;
        color: var(--theme-font-color-light);
        transition: transition.$default color;

        &:hover {
          color: var(--theme-font-color-light);
        }
      }

      a {
        color: var(--theme-font-color-lighter);
        white-space: nowrap;
        transition: transition.$default color;

        &.current {
          color: var(--theme-font-color-light);
        }
      }
    }
  }
}
