@use '../../variable/breakpoint';
@use '../../variable/z-index';
@use '../../mixin/container';
@use '../../../stories/scss/settings/spacing';

.product-list {
  &__collapsed {
    @include container.overlay-gradient(
      $_overlay-gradient-height: 100%
    );

    @at-root & {
      position: absolute;
      inset: 0;
      z-index: z-index.$low-2;
    }

    &__button {
      z-index: z-index.$low-3;
    }
  }

  &__load {
    margin-top: spacing.$s10;

    @media screen and (min-width: breakpoint.$lg) {
      margin-top: spacing.$s18;
    }

    p {
      margin-bottom: spacing.$s8;
    }
  }
}
