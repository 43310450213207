// Variable
@use '../../../variable/breakpoint';
@use '../../../variable/color';
@use '../../../variable/font';
@use '../../../../stories/scss/settings/spacing';
@use '../../../variable/shadow';
@use '../../../variable/transition';
@use '../../../variable/components/header';

// Component
@use '../../overlay/overlay-base';

// Mixin
@use '../../../mixin/container';

/* stylelint-disable max-nesting-depth */

body [id] {
  scroll-margin-top: calc(#{header.$mobileHeaderHeight} + #{spacing.$s2});

  @media screen and (min-width: breakpoint.$lg) {
    scroll-margin-top: calc(#{header.$desktopHeaderHeight} + #{spacing.$s2});
  }
}

.main-nav {
  $nav: &;
  padding-bottom: spacing.$s6;

  &__right {
    border-top: 1px solid var(--theme-color-base);
    padding-top: spacing.$s6;
  }

  @media screen and (min-width: breakpoint.$lg) {
    display: flex;
    padding-bottom: 0;
    column-gap: spacing.$s2;

    &__right {
      display: flex;
      margin-left: auto;
      border-top: 0;
      padding-top: 0;
    }
  }

  /**
    * Header Element
    *
    */
  &__header {
    position: sticky;
    top: 0;
    width: 100%;
    margin-bottom: spacing.$s6;

    @media screen and (min-width: breakpoint.$lg) {
      display: none;
    }

    &__first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: spacing.$s4;
      border-bottom: 1px solid var(--theme-color-base);
      background-color: var(--theme-color-background);
    }

    &--language {
      display: none;

      @media screen and (min-width: breakpoint.$lg) {
        display: block;

        position: static;

        border-bottom: 0;
        padding: 32px 0 0;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: transparent;
      border: 0;
      width: 28px;
      height: 28px;
      padding: 0;

      cursor: pointer;

      i {
        font-size: 16px;
      }
    }

    &-title {
      font-family: font.$secondary;
      font-size: font.$size-sm;
    }

    &__second {
      padding: spacing.$s8 spacing.$s4;

      &.is-open {
        transform: translate(0);
      }

      @media screen and (min-width: breakpoint.$lg) {
        display: none;
      }

      &-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        font-family: font.$primary;
        font-size: font.$size-base;
        color: var(--theme-font-color);
      }
    }
  }

  &-list {
    margin: 0 0 spacing.$s6;
    padding: 0;
    list-style: none;

    &--country {
      padding-top: spacing.$s6;
      border-top: 1px solid var(--theme-color-base);

      @media screen and (min-width: breakpoint.$lg) {
        padding-top: 0;
        border-top: 0;
      }
    }

    &:hover {
      &:after {
        @extend .overlay;
      }
    }

    @media screen and (min-width: breakpoint.$lg) {
      display: flex;
      margin: 0;

      &:hover {
        #{ $nav }-link {
          color: var(--theme-font-color-lighter);
        }

        .link--highlight {
          color: var(--theme-font-color-lighter);
        }
      }
    }
  }

  &-item {
    &--parent {
      &:hover {
        &:after {
          @extend .overlay;
        }
      }
    }

    @media screen and (min-width: breakpoint.$lg) {
      padding-right: spacing.$s4;
      height: 50px;

      &:last-of-type {
        padding-right: 0;
      }

      &--parent {
        &:after {
          z-index: 82;
          content: '';
        }

        &:hover {
          &:after {
            top: 169px;
            pointer-events: none;
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &--country {
        padding-right: 0;
        padding-left: spacing.$s6;
      }

      &:hover,
      &:focus,
      &:focus-within {
        #{ $nav }-link {
          color: var(--theme-font-color);
        }
        .link--highlight {
          color: var(--theme-color-secondary);
        }
      }

      &:hover,
      #{ $nav }-list--country &:hover {
        @supports (clip-path: polygon(50% 0%, 0% 100%, 100% 100%)) {
          #{ $nav }-link {
            z-index: 16;

            // stylelint-disable-next-line selector-max-compound-selectors
            &:before {
              content: '';
              position: absolute;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              top: 0;
              left: 50%;
              width: 250%;
              height: 52px;
              clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            }

            // stylelint-disable-next-line selector-max-compound-selectors
            &:only-child:before {
              display: none;
            }
          }
        }

        & > #{ $nav }__dropdown {
          visibility: visible;
          opacity: 1;
          pointer-events: all;
          transform: translate(0);
        }
      }
    }


    @media screen and (min-width: breakpoint.$xl) {
      padding-right: spacing.$s6;
    }

    &--account,
    &--language {
      display: block;

      @media screen and (min-width: breakpoint.$lg) {
        display: none;
      }
    }

    &--title {
      padding: 0 spacing.$s4 10px;

      @media screen and (min-width: breakpoint.$lg) {
        display: none;
      }
    }

    &--country {
      margin-bottom: spacing.$s6;

      @media screen and (min-width: breakpoint.$lg) {
        margin-bottom: 0;
        margin-right: 6px;
      }
    }
  }

  &-title {
    font-family: font.$secondary;
    font-size: font.$size-xs;
    margin: 0;

    @media screen and (min-width: breakpoint.$lg) {
      display: none;
    }
  }

  &-link {
    display: flex;
    justify-content: space-between;
    align-items: center;

    text-align: left;
    cursor: pointer;
    width: 100%;
    height: 46px;

    background-color: var(--theme-color-background);
    border: none;
    padding: 0 spacing.$s4;

    text-decoration: none;
    font-family: font.$primary;
    font-size: font.$size-base;

    // For flag image
    img {
      height: 16px;
      margin-right: spacing.$s2;
      border-radius: var(--theme-border-radius-xs);
    }

    i {
      margin-left: auto;
      transform: rotate(-90deg);
    }

    &:hover {
      text-decoration: none;
    }

    @media screen and (min-width: breakpoint.$lg) {
      align-items: center;

      position: relative;

      text-align: center;
      height: 100%;
      padding: 2px 0 spacing.$s2;

      background-color: transparent;

      transition: transition.$default color;

      &:hover,
      &:focus-within,
      &:focus {
        outline: 0;
        text-decoration: none;
      }

      // For flag image
      img {
        margin-right: 0;
      }

      // Chevron icon
      i {
        margin-left: 4px;
        transform: none;
        position: relative;
        bottom: 1px;
      }
    }

    &-name {
      display: block;

      @media screen and (min-width: breakpoint.$lg) {
        #{ $nav }-item--country &,
        #{ $nav }-item--language & {
          display: none;
        }

        #{ $nav }__content & {
          display: block;
        }
      }
    }
  }

  /**
  * Dropdown
  * This is the first layer and visible in the header
  */
  &__dropdown {
    position: absolute;
    transform: translate(100%, 0);
    top: 0;

    @media screen and (min-width: breakpoint.$lg) {
      position: absolute;
      transition: none;
      transform: translate(-200%, 0);
      top: auto;
      left: 0;
    }

    &--first {
      padding-top: 61px;
      height: 100%;
      width: 100%;
      background-color: var(--theme-color-background);
      overflow-y: auto;
      overflow-x: hidden;
      transition: transform 0.4s;
      transition-timing-function: cubic-bezier(0.1, 0, 0.1, 1);

      &.is-open {
        transform: translate(0, 0);
      }

      @media screen and (min-width: breakpoint.$lg) {
        overflow: visible;
        border-top: 1px solid var(--theme-color-base);
        box-shadow: 0 1px 0 var(--theme-color-base);
        visibility: hidden;
        opacity: 0;
        height: auto;
        pointer-events: none;
        transition: none;
        padding-top: 32px;
        padding-bottom: 32px;
        box-sizing: content-box;

        &.is-open {
          visibility: visible;
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    &--country {
      padding-top: 48px;

      @media screen and (min-width: breakpoint.$lg) {
        min-height: 100%;
        height: auto;
        padding-top: 0;

        overflow-y: visible;
        overflow-x: visible;

        &.is-open {
          height: auto;
        }
      }
    }

    &--second {
      position: static;
      transform: translate(0, 0);
      width: 100%;

      @media screen and (min-width: breakpoint.$lg) {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100%;

        top: 0;
        right: 0;
        left: 0;
        display: flex;
        visibility: hidden;
        overflow: visible;
        padding: 32px 0;
        opacity: 0;

        &__inner {
          @include container.wrapper;
          width: 100%;
          height: 100%;
          overflow-y: auto;

          &-spacer {
            margin-left: 344px;
            height: 100%;
          }
        }
      }
    }

    &--box {
      position: static;
      transform: none;
      width: 100%;

      @media screen and (min-width: breakpoint.$lg) {
        display: block;
        transform: translate(0, 0);
        position: absolute;
        top: 100%;
        box-shadow: shadow.$drop-bottom;
        visibility: hidden;
        opacity: 0;
        height: 0;
        pointer-events: none;
        transition: opacity 0.1s ease-in-out;
        transition-delay: 0.1s;
        z-index: 25;
        background-color: var(--theme-color-background);
        border-radius: var(--theme-border-radius-md);
        backdrop-filter: blur(8px);

        &.is-open {
          visibility: visible;
          opacity: 1;
          height: auto;
          pointer-events: all;
        }
      }
    }

    @media screen and (min-width: breakpoint.$lg) {
      &__wrapper {
        @include container.wrapper;

        position: static;
        height: 100%;

        .main-nav__dropdown--country & {
          height: auto;
        }
      }
    }
  }

  /**
    * Second level navigation
    * This is the left sidebar in the dropdown
    */

  &__second {
    $nav-second: &;
    border-top: 1px solid var(--theme-color-base);
    padding-top: spacing.$s6;

    &--language {
      border-top: 0;
    }

    @media screen and (min-width: breakpoint.$lg) {
      border-top: none;
      padding-top: 0;

      &.is-open {
        display: block;
      }

      &--language {
        position: static;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;

        padding-top: 0;
        overflow: unset;
      }
    }

    &-list {
      margin: 0 0 spacing.$s6;
      padding: 0;
      list-style: none;

      @media screen and (min-width: breakpoint.$lg) {
        border: 0;
        display: block;
        width: 320px;
        pointer-events: auto;
        margin-bottom: 0;

        list-style: none;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100svh - (var(--menu-desktop-bottom-offset, 156px)));

        &:hover,
        &:focus,
        &:focus-within {
          #{ $nav-second }-link {
            color: var(--theme-font-color-lighter);
          }
        }

        #{ $nav-second }--language & {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 0 32px;
          width: auto;
          grid-auto-flow: column;
          grid-template-rows: repeat(6, auto);
          overflow: visible;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
        }
      }
    }

    &-item {
      @media screen and (min-width: breakpoint.$lg) {
        &:hover,
        &:focus,
        &:focus-within {
          #{ $nav-second }-link {
            color: var(--theme-font-color);
          }
          .link--highlight {
            color: var(--theme-color-secondary);
          }
        }

        &:hover {
          @supports (clip-path: polygon(0 50%, 100% 100%, 100% 0)) {
            &:not(#{ $nav-second }--language &) {
              #{ $nav-second }-link {
                z-index: 16;

                &:before {
                  content: '';
                  position: absolute;
                  transform: translateY(-50%);
                  top: 50%;
                  right: -1px;
                  width: 70%;
                  height: 130px;
                  clip-path: polygon(0 50%, 100% 100%, 100% 0);
                }
              }
            }
          }

          & > #{ $nav }__dropdown {
            visibility: visible;
            min-height: 100%;
            opacity: 1;

            #{ $nav-second }--language & {
              height: auto;
              pointer-events: all;
            }
          }
        }

        #{ $nav-second }--language & {
          position: relative;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 20%;
          }
        }
      }
    }

    &-link {
      display: flex;
      justify-content: space-between;
      align-items: center;

      text-align: left;
      cursor: pointer;
      width: 100%;
      height: 46px;

      background-color: var(--theme-color-background);
      border: none;
      padding: 0 spacing.$s4;

      color: var(--theme-font-color);
      font-size: font.$size-base;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      #{ $nav-second }--language & {
        justify-content: flex-start;
      }

      img {
        height: 16px;
        margin-right: 8px;
        border-radius: var(--theme-border-radius-xs);
      }

      @media screen and (min-width: breakpoint.$lg) {
        width: 100%;
        height: auto;
        z-index: 15;
        position: relative;

        border: 0;
        padding: 12px 0;
        background-color: transparent;

        transition: transition.$default color;

        i {
          transform: rotate(270deg);
        }

        img {
          margin-right: 16px;
          margin-top: -1px;
        }

        .main-nav__dropdown--country & {
          padding: 12px 0;

          i {
            transform: none;
          }
        }
      }
    }
  }

  /**
  * Third level navigation
  * This is the third layer and as content in the dropdown if you hover over the sidebar
  */

  &__third {
    $nav-third: &;
    padding: spacing.$s8 spacing.$s4;
    background-color: var(--theme-color-white);

    @media screen and (min-width: breakpoint.$lg) {
      background-color: transparent;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      padding: 6px 0;
      border-top: 0;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &__header {
      display: none;

      @media screen and (min-width: breakpoint.$lg) {
        display: none;
      }
    }

    > div {
      margin: 0 0 spacing.$s4;
      list-style: none;

      &:first-of-type .nav-link-title {
        padding-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      @media screen and (min-width: breakpoint.$lg) {
        margin: 0;
        padding: 0;
        border-bottom: 0;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 25%;
        }

        &.top-link {
          display: none;
        }
      }
    }

    .nav-link-title {
      line-height: 28px;
      font-weight: 600;
      color: var(--theme-font-color);
      padding: 0;

      @media screen and (min-width: breakpoint.$lg) {
        padding: 0;
        margin: 0 0 4px 0;

        #{ $nav-third }__header & {
          font-size: 18px;
        }
      }
    }

    a {
      display: block;
      padding: 5px 0;

      @media screen and (min-width: breakpoint.$lg) {
        padding: 5px 0;
        margin: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  /**
    * Fourth level navigation
    * Used to display different languages for some countries
    */

  &__fourth {
    padding: spacing.$s4;
    background-color: var(--theme-color-white);

    @media screen and (min-width: breakpoint.$lg) {
      border-radius: var(--theme-border-radius-sm);
    }

    &-list {
      margin: 10px 0 0;
      padding: 10px 0 0;

      list-style: none;
      border-top: 1px solid var(--theme-color-base);

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }

      @media screen and (min-width: breakpoint.$lg) {
        margin: 0;
        padding: 0;
        border-top: 0;
      }
    }

    &-item {
      &:not(:last-child){
        margin-bottom: spacing.$s4;
      }

      @media screen and (min-width: breakpoint.$lg) {
        padding: 0 5px;
        margin: 0;
        list-style: none;
      }
    }

    &-title {
      margin: 0 0 4px;

      line-height: 28px;
      font-size: font.$size-base;
      font-family: font.$primary;
      color: var(--theme-font-color);

      @media screen and (min-width: breakpoint.$lg) {
        margin: 0 12px 5px;
        line-height: 32px;
        border-bottom: 1px solid var(--theme-color-base);
      }
    }

    &-link {
      display: block;
      padding: 0;
      color: var(--theme-font-color);

      @media screen and (min-width: breakpoint.$lg) {
        display: block;
        margin: 0;

        border: 0;
        list-style: none;
        text-align: left;

        &:hover,
        &:focus {
          text-decoration: none;
          outline: 0;
        }
      }
    }
  }
}
