@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';

.product-filter {
  scroll-snap-align: center;
  margin-left: spacing.$s2;
  white-space: nowrap;
  cursor: pointer;

  &-wrapper {
    @media screen and (min-width: breakpoint.$lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &-group {
    display: flex;

    @media screen and (min-width: breakpoint.$lg) {
      flex-wrap: wrap;
    }

    & ~ .product-filter-group--no-scroll {
      margin-top: spacing.$s6;
      padding-left: spacing.$s2;
      padding-right: spacing.$s2;

      @media screen and (min-width: breakpoint.$lg) {
        margin-top: 0;
        padding-left: 0;
      }
    }

    &--no-scroll {
      align-items: center;
      justify-content: space-between;

      @media screen and (min-width: breakpoint.$lg) {
        justify-content: flex-end;
      }
    }

    &:not(.product-filter-group--no-scroll) {
      margin-left: calc(#{spacing.$s2} * -1);
      margin-right: calc(#{spacing.$s2} * -1);
      padding-left: spacing.$s4;
      padding-right: spacing.$s4;
      -ms-overflow-style: none; // for Internet Explorer, Edge
      scrollbar-width: none; // for Firefox
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none; // for Chrome, Safari, and Opera
      }

      @media screen and (min-width: breakpoint.$md) {
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }

    & > * {
      margin-right: spacing.$s2;

      @media screen and (min-width: breakpoint.$lg) {
        margin-bottom: spacing.$s2;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-results {
      margin-right: spacing.$s6;
      display: inline-block;
      color: color.$base-60;

      .product-list__load & {
        margin-top: 0;
      }
    }

    &--active {
      border-top: 1px solid var(--theme-color-base);
      padding-top: spacing.$s6;
      margin-top: spacing.$s6;

      @media screen and (min-width: breakpoint.$lg) {
        padding-top: 0;
        border-top: 0;
        padding-left: spacing.$s4;
        padding-right: spacing.$s4;
      }

      &:empty {
        display: none;
      }
    }
  }
}
