@use '../../variable/transition';
@use '../../variable/font';
@use '../../mixin/container';

$ysp-collapse_preview-height: 130px;

.ysp-collapse {
  $p: &;

  &--in {
    #{$p}--in-hide {
      display: none;
    }
  }

  &--out {
    #{$p}--out-hide {
      display: none;
    }
  }

  &__item {
    i {
      display: inline-block;
      margin-left: auto;

      #{$p}--out & {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    display: grid;
    transition: grid-template-rows transition.$slow;

    &--preview {
      min-height: $ysp-collapse_preview-height;
      @include container.overlay-gradient;

      #{$p}--out & {
        @include container.overlay-gradient-disable(
          $_overlay-gradient-disable-animation: true
        );
      }
    }

    #{$p}--out & {
      grid-template-rows: 1fr;
    }

    #{$p}--in & {
      grid-template-rows: 0fr;
    }

    > * {
      grid-row: 1 / span 2;
      overflow: hidden;
    }
  }
}
