@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/shadow';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';

body:has(#mysurprise-app-ssr) .cm-button {
  bottom: 68px;

  @media screen and (min-width: breakpoint.$md) {
    bottom: spacing.$s6;
  }
}

.cm-button {
  position: fixed;
  right: spacing.$s4;
  bottom: spacing.$s4;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid var(--theme-color-white);
  padding: 0;
  margin: 0;
  background-color: var(--theme-color-white);
  box-shadow: shadow.$drop-dark-bottom;
  transition: transition.$default box-shadow, 0.4s ease-in-out transform;

  &--hidden {
    display: none;
  }

  @media screen and (min-width: breakpoint.$md) {
    right: spacing.$s6;
    bottom: spacing.$s6;
    width: 64px;
    height: 64px;
  }

  &:hover {
    box-shadow: shadow.$drop-darker-bottom;
    transform: translate(0, -2px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
