// Variable
@use '../variable/breakpoint';
@use '../variable/font';
@use '../variable/transition';

body:not(.page-manager) {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-color-background);

  &.height-auto {
    display: block;
    min-height: auto;
    height: auto;
  }
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}


i {
  font-size: font.$size-icon;
}

button {
  cursor: pointer;
  color: var(--theme-font-color);
  font-family: font.$primary;
  transition: transition.$default box-shadow;
}

a {
  color: var(--theme-font-color);
}

input,
select,
textarea {
  font-family: font.$primary;
  transition: transition.$default border;

  &::-webkit-input-placeholder {
    color: var(--theme-font-color-light);
  }

  &::-moz-placeholder {
    color: var(--theme-font-color-light);
  }

  &:-ms-input-placeholder {
    color: var(--theme-font-color-light);
  }

  &:-moz-placeholder {
    color: var(--theme-font-color-light);
  }

  // CSS specific to iOS devices - this might change in the future
  @supports (-webkit-touch-callout: none) {
    // Remove zoom on iOS
    @media screen and (max-width: breakpoint.$xs) {
      /* stylelint-disable-next-line declaration-no-important */
      font-size: 16px !important;
      color: var(--theme-font-color);
    }
  }
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration { display: none; }

