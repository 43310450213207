// Variable
@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
// NOTE: Contains branded styling elements!
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 18020;
}

.fancybox-skin {
  position: relative;
  background: var(--theme-color-background);
  border-radius: var(--theme-border-radius-sm);
  text-shadow: none;
}

.fancybox-opened {
  z-index: 18030;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: breakpoint.$md) {
    flex-direction: row;
  }

  .content {
    padding: spacing.$s10 spacing.$s6 spacing.$s6 !important;
    overflow: auto;
    margin-bottom: 0 !important;
    width: 100%;
    max-height: 320px;
    max-width: 720px;

    @media screen and (min-width: breakpoint.$md) {
      padding: spacing.$s8 !important;
    }

    [class^='ProductOverview'] & {
      padding-bottom: spacing.$s10 !important;

      @media screen and (min-width: breakpoint.$md) {
        padding-bottom: spacing.$s8 !important;
      }
    }
  }

  [class^='ProductModal'] + .content {
    padding: spacing.$s10 !important;
  }
}

.issuuembed {
  width: 100%;
  height: 400px;
  overflow: hidden;

  @media screen and (min-width: breakpoint.$md) {
    max-width: 780px;
    height: 600px;
  }
}


.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444444;
  font-size: font.$size-xxs;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-iframe {
  .article & {
    height: auto;
  }
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-prev span, .fancybox-next span {
  background-image: url('/images/algemeen/fancybox/fancybox_sprite.png');
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 18060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('/images/algemeen/fancybox/fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
  border-radius: 100%;
  box-sizing: content-box;
  position: absolute;
  cursor: pointer;
  z-index: 18040;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  vertical-align: baseline;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  margin-top: 0;
  font-family: font.$icon;
  speak: none;
  font-style: normal;
  font-weight: 300;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  background-color: color.$bg-grey;
  color: color.$base-90;
  display: inline-block;
  text-align: center;
  text-rendering: auto;
  border: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .ysp-fancybox-dark & {
    @media screen and (max-width: breakpoint.$md) {
      background-color: rgb(from var(--theme-color-white) r g b / 0.6);
    }
  }

  @media screen and (min-width: breakpoint.$md) {
    top: -20px;
    right: -20px;
    background-color: color.$base-80;
    backdrop-filter: blur(10px);
    color: var(--theme-color-white);
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.75);
  }

  &:before {
    content: '\f00d';
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: var(--theme-color-background);
  }
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url('/images/algemeen/fancybox/blank.gif'); /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 18040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 18040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Backdrop helper */

.fancybox-lock {
  overflow: visible !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 18010;
  background: url('/images/algemeen/fancybox/fancybox_overlay.png');

  &.is-open {
    display: flex;
  }
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font-size: font.$size-xxs;
  position: relative;
  text-shadow: none;
  z-index: 18050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 18050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent; /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222222;
  color: var(--theme-color-white);
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: var(--theme-color-white);
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--theme-color-white);
  padding: 10px;
  background: #000000;
  background: rgba(0, 0, 0, .8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-prev span, .fancybox-next span {
    background-image: url('/images/algemeen/fancybox/fancybox_sprite@2x.png');
    background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
  }

  #fancybox-loading div {
    background-image: url('/images/algemeen/fancybox/fancybox_loading@2x.gif');
    background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
  }
}

