@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';

.filter-price-handle {
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--theme-color-base);
  background-color: var(--theme-color-white);
  border-radius: 50%;
  outline: none;
  cursor: grab;
}
.filter-price-handle:after {
  display: block;
  position: absolute;
}
.filter-price-handle:before {
  display: block;
  position: absolute;
}
.filter-price-wrapper {
  position: relative;
  overflow: visible;
  margin-top: spacing.$s6;
}
.filter-price-value-container {
  display: flex;
  justify-content: space-between;
  margin-top: spacing.$s4;
  color: var(--theme-font-color);
}
.filter-price-progress-bar-wrapper {
  height: 24px;
  display: flex;
  width: 100%;
}
.filter-price-progress-bar {
  width: 100%;
  height: 2px;
  align-self: center;
}
