@use '../../variable/breakpoint';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.article {
  color: var(--theme-font-color);

  @media screen and (min-width: breakpoint.$md) {
    &:first-child {
      padding-right: spacing.$s18;
    }

    &:last-child {
      padding-left: spacing.$s18;
    }
  }

  h1 {
    margin-bottom: spacing.$s10;
  }

  div + h2 {
    margin-top: spacing.$s10;
  }

  h2,
  .artikel-head {
    margin-bottom: spacing.$s6;
    margin-top: spacing.$s10;
  }

  h3 {
    margin-bottom: spacing.$s6;
  }

  .artikel-head {
    font-family: font.$secondary;
    font-size: font.$size-xs;

    @media screen and (min-width: breakpoint.$md) {
      font-size: font.$size-sm;
    }
  }

  hr {
    height: 1px;
    background-color: var(--theme-color-base);
    border: 0;
    margin: spacing.$s10 0;

    &:last-child {
      display: none;
    }
  }

  strong {
    font-weight: 600;
  }

  address {
    margin-bottom: spacing.$s6;
  }

  ul {
    margin-bottom: spacing.$s10;
    padding-left: spacing.$s6;

    li {
      list-style: disc;
      padding: calc(#{spacing.$s2} * 0.5) 0;
    }
  }

  ol {
    margin-bottom: spacing.$s10;
    padding-left: spacing.$s6;

    li {
      list-style: decimal;
      padding: calc(#{spacing.$s2} * 0.5) 0;
    }

    div {
      ul {
        margin-bottom: 0;

        /* stylelint-disable-next-line selector-max-compound-selectors */
        li {
          list-style: disc;
        }
      }
    }
  }

  p {
    margin-bottom: spacing.$s6;

    a {
      text-decoration: underline;
    }
  }
}
