@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.list {
  &-heading {
    margin-bottom: 24px;
  }

  &-items {
    &--bullet {
      padding-left: spacing.$s10;

      @media screen and (max-width: breakpoint.$md) {
        .hero & {
          padding-left: 0;
        }
      }

      li {
        list-style: disc;
        color: color.$base-80;

        @media screen and (max-width: breakpoint.$md) {
          .hero & {
            list-style: none;
          }
        }
      }
    }

    li {
      padding: calc(#{spacing.$s2} * 0.5) 0;

      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
