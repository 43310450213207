@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../variable/shadow';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';

.gift-help {
  position: fixed;
  right: spacing.$s4;
  bottom: spacing.$s4;
  max-width: 90vw;

  @media screen and (min-width: breakpoint.$lg) {
    right: spacing.$s6;
    bottom: spacing.$s6;
  }

  &__button {
    border: none;
    background: none;
    padding: 0;
  }

  // Dialogue that pops open above the gift help when you click on call
  &__popup {
    position: relative;
    background-color: color.$base-80;
    color: var(--theme-color-white);
    padding: spacing.$s2 spacing.$s6;
    font-size: 22px;
    margin-bottom: spacing.$s6;
    margin-right: spacing.$s2;
    max-width: 220px;

    &::before {
      transform: rotate(45deg);
      content: '';
      position: absolute;
      bottom: -10px;
      left: spacing.$s28;
      width: 20px;
      height: 20px;
      background: inherit;
      z-index: -1;
    }

    i {
      font-size: 18px;
    }

    a {
      color: var(--theme-color-white);
      text-decoration: none;
    }

    &__note {
      font-size: 12px;
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 25px;
      height: 25px;
      background-color: color.$base-80;
      color: var(--theme-color-white);
      border-radius: 100%;
      cursor: pointer;

      i {
        font-size: 12px;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__user {
    position: relative;
    border-radius: 100%;
    background-color: var(--theme-color-white);
    width: 64px;
    height: 64px;
    cursor: pointer;
    border: 2px solid var(--theme-color-white);
    box-shadow: shadow.$drop-bottom;
    transition: transition.$default box-shadow, 0.4s ease-in-out transform;

    #productpage ~ #giftHelpApp & {
      width: 48px;
      height: 48px;

      @media screen and (min-width: breakpoint.$lg) {
        width: 64px;
        height: 64px;
      }
    }

    &:hover {
      box-shadow: shadow.$drop-dark-bottom;
      transform: translate(0, -2px);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -2px;
      right: -2px;
      width: 24px;
      height: 24px;
      background-color: color.$base;
      color: var(--theme-color-white);
      border-radius: 50%;
      cursor: pointer;
      z-index: 7;

      i {
        font-size: font.$size-icon;
      }
    }

    &__status {
      position: absolute;
      top: 0;
      right: -5px;
      width: 16px;
      height: 16px;
      background-color: var(--theme-color-primary);
      border-radius: 50%;
      z-index: 6;

      &--closed {
        background-color: color.$base;
      }
    }
  }

  &__contact,
  &__message {
    display: flex;
    padding-left: spacing.$s2;
    padding-right: spacing.$s2;
    position: relative;
    background-color: var(--theme-color-white);
    border-radius: 80px;
    box-shadow: 0 0 0 1px var(--theme-color-base);
    margin-left: spacing.$s2;

    @media screen and (min-width: breakpoint.$md) {
      margin-left: spacing.$s6;
    }

    &__item {
      padding: spacing.$s4;
      text-align: center;
      font-size: font.$size-xxs;

      @media screen and (min-width: breakpoint.$md) {
        font-size: font.$size-base;
      }

      a {
        color: var(--theme-font-color);
        font-size: font.$size-xxs;
        text-decoration: none;

        @media screen and (min-width: breakpoint.$md) {
          font-size: font.$size-base;
        }
      }

      i {
        display: block;
        margin-bottom: calc(#{spacing.$s2} * 0.5);
        font-size: font.$size-icon;

        @media screen and (min-width: breakpoint.$md) {
          margin-bottom: spacing.$s2;
        }
      }
    }
  }

  &__message {
    display: block;
    padding: spacing.$s4;

    @media screen and (min-width: breakpoint.$md) {
      padding: spacing.$s6 spacing.$s10;
    }

    i {
      font-size: font.$size-icon;
      margin-right: calc(#{spacing.$s2} * 0.5);
      position: relative;
      top: 2px;
    }
  }

  .chatWindow {
    display: none;

    button.endChat {
      float: right;
      width: 120px;
      margin-top: 0;

      i {
        font-size: 22px;
      }
    }
  }
}
