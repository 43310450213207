@use '../../../scss/variable/breakpoint';

.hide-xs {
  @media screen and (max-width: breakpoint.$md) {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

.hide-md {
  @media screen and (min-width: breakpoint.$md) {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

.hide-lg {
  @media screen and (min-width: breakpoint.$lg) {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

.show-lg {
  @media screen and (max-width: calc(#{breakpoint.$lg} - 1px)) {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

.hide {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.semi-hidden {
  visibility: hidden;
  opacity: 0;
}

.d {
  &--grid {
    display: grid;
  }
  &--flex {
    display: flex;
  }
}

.f-1 {
  flex: 1;
}

.f-a-i {
  align-items: center;
}

.f-jc {
  &--start {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--between {
    justify-content: space-between;
  }

  &--end {
    justify-content: flex-end;
  }
}

.o-h {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.pointer-events {
  &--none {
    pointer-events: none;
  }
}




