// Mixins
@use '../mixin/container';

// Variables
@use '../variable/breakpoint';
@use '../../stories/scss/settings/spacing';
@use '../variable/grid';

// SASS
@use 'sass:math';
@use 'sass:list';
@use 'sass:string';

// Functions
@function get_first_chars($word, $number) {
  @return string.slice($word, 0, $number);
}

@function variable($var_name) {
  @return var(--#{$var_name});
}

// Class Generation
@mixin generate_gap($grid_direction) {
  // Generate Grid Gap Classes --- Ex: .has-col-gap-md (medium column gap
  $first_letters_of_direction: get_first_chars($grid_direction, 3);

  @each $size in grid.$sizes {
    @each $direction in $grid_direction {
      .has-#{$first_letters_of_direction}-gap#{$size} {
        grid-#{$direction}-gap: variable(#{'spacing#{$size}'});
      }
    }
  }

  // No Col/Row Gap Class --- Ex: .has-no-col-gap
  .has-no-#{$first_letters_of_direction}-gap { grid-#{$grid_direction}-gap: 0; }

  @media screen and (max-width: breakpoint.$md) {
    .has-no-col-gap-md {
      grid-column-gap: 0;
    }

    .has-no-row-gap-md {
      grid-row-gap: 0;
    }

    .has-row-gap-md {
      grid-row-gap: variable(spacing-xs);
    }
  }

  @media screen and (max-width: breakpoint.$lg) {
    .has-no-col-gap-lg {
      grid-column-gap: 0;
    }

    .has-no-row-gap-lg {
      grid-row-gap: 0;
    }
  }
}

@mixin generate_direction($direction) {
  // Generate Direction Classes --- Ex: .is-rtl or .is-ltr
  @for $i from 1 through list.length(grid.$breakpoints) {
    .is-#{$direction} { direction: #{$direction}; }
    .is-#{$direction}-#{list.nth(grid.$breakpoints, $i)} {
      @media screen and (min-width: #{list.nth(grid.$breakpoint_values, $i)}) { direction: #{$direction}; }
    }
  }
}

@mixin generate_place_self() {
  @media screen and (min-width: breakpoint.$lg) {
    // Generate Place Self Classes --- Ex: .is-placed-left
    @each $property in grid.$properties {
      .is-placed-#{$property} {
        align-self: #{$property};
        justify-self: #{$property};
      }
    }
  }
}

@mixin generate_order_classes($grid_direction) {
  // Generate Order Classes --- Ex: is-order-2-lg
  @for $i from 1 through grid.$maximum {
    .is-order-#{$i} { order: #{$i}; }

    @for $b from 1 through list.length(grid.$breakpoints) {
      .is-order-#{$i}-#{list.nth(grid.$breakpoints, $b)} {
        @media screen and (min-width: #{list.nth(grid.$breakpoint_values, $b)}) { order: #{$i}; }
      }
    }
  }
}

@mixin default_grid_flow {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-template-columns: repeat(#{grid.$maximum}, 1fr); // 12 Column Grid By Default
}

// Class Generation
@mixin grid_item_breakpoint_spans($name, $breakpoint, $spans, $grid_direction) {
  // is-{col|row}-{breakpoint}-{number} --- Ex: .is-col-6-lg
  $first_letters_of_direction: get_first_chars($grid_direction, 3);
  .is-#{$first_letters_of_direction}-#{$spans} {
    grid-#{$grid_direction}: span #{$spans};
  }

  @media screen and (min-width: $breakpoint) {
    .is-#{$first_letters_of_direction}-#{$spans}-#{$name} {
      /* stylelint-disable-next-line declaration-no-important */
      grid-#{$grid_direction}: span #{$spans} !important;
    }
  }
}

@mixin grid_item_spans($grid_direction) {
  @for $b from 1 through list.length(grid.$breakpoints) {
    @for $i from 1 through 12 {
      @include grid_item_breakpoint_spans(#{list.nth(grid.$breakpoints, $b)}, #{list.nth(grid.$breakpoint_values, $b)}, $i, $grid_direction);
    }
  }
}

@mixin grid_has($grid_direction) {
  // Generate Grid Grid Template Classes --- Ex: .has-col-6 or .has-col-4-md
  $first_letters_of_direction: get_first_chars($grid_direction, 3);

  @for $i from 1 through grid.$maximum {
    .has-#{$first_letters_of_direction}-#{$i} {
      grid-template-#{$grid_direction}: repeat($i, 1fr);
      & > * { // Items without a .is-col class are 12 by default
        grid-column: span #{$i};
        @media screen and (min-width: breakpoint.$lg) { grid-column: span 1; }
      }
    }

    @for $b from 1 through list.length(grid.$breakpoints) {
      .has-#{$first_letters_of_direction}-#{$i}-#{list.nth(grid.$breakpoints, $b)} {
        & > * { // Items without a .is-col class are 12 by default
          grid-column: span #{$i};
          @media screen and (min-width: breakpoint.$lg) { grid-column: span 1; }
        }

        @media screen and (min-width: #{list.nth(grid.$breakpoint_values, $b)}) {
          /* stylelint-disable-next-line declaration-no-important */
          grid-template-#{$grid_direction}: repeat($i, 1fr) !important;
        }
      }
    }
  }
}

// Grid
.is-grid,
.is-grid-inline {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(#{grid.$maximum}, 1fr); // 12 Column Grid By Default
  direction: ltr;
  grid-column-gap: variable('spacing-default');
  grid-row-gap: variable('spacing-default');

  @media screen and (min-width: breakpoint.$xl) {
    grid-column-gap: variable('spacing-xs');
    grid-row-gap: variable('spacing-xs');
  }

  & > * { // Items without a .is-col class are 12 by default
    grid-column: span #{grid.$maximum};
    @media screen and (min-width: breakpoint.$lg) { grid-column: span 1; }
  }

  &.grid-center-items {
    place-items: center;
  }

  &.is-dense {
    grid-auto-flow: dense;
    @include default_grid_flow;
  }

  &.has-wrapped-row {
    grid-auto-flow: row;
    @include default_grid_flow;

    &.is-dense {
      grid-auto-flow: row dense;
      @include default_grid_flow;
    }
  }

  &.has-wrapped-col {
    grid-auto-flow: column;

    &.is-dense {
      grid-auto-flow: column dense;
      @include default_grid_flow;
    }
  }

  @media screen and (max-width: breakpoint.$md) {
    &.has-max-width {
      grid-template-columns: repeat(#{grid.$maximum}, minmax(240px, 1fr)); // 12 Column Grid By Default
    }
  }
}

.is-grid-inline {
  display: inline-grid;
}

.is-first {
  order: -1;

  @media screen and (min-width: breakpoint.$lg) {
    order: inherit;
  }
}

.custom-grid {
  display: grid;
  grid-gap: variable('spacing-default');
  grid-row-gap: variable('spacing-default');
  grid-template-columns: 1fr;

  @media screen and (min-width: breakpoint.$lg) {
    grid-gap: variable('spacing-md');
    grid-template-columns: minmax(1em, 1fr) repeat(grid.$maximum, minmax(0, math.div(1376px + 32px, grid.$maximum) - 32px)) minmax(1em, 1fr);
  }

  & > * {
    display: flex;
    align-items: center;
  }

  &__image {
    grid-column-start: 1;
    grid-column-end: 15;

    @media screen and (min-width: breakpoint.$lg) {
      grid-column-start: 1;
      grid-column-end: 8;

      .is-off-right & {
        grid-column-start: 8;
        grid-column-end: 15;
      }
    }

    @media screen and (min-width: breakpoint.$xxxl) {
      img {
        margin-left: auto;
      }
    }
  }

  &__content {
    grid-column-start: 2;
    grid-column-end: 14;

    @media screen and (min-width: breakpoint.$lg) {
      grid-column-start: 8;
      grid-column-end: 14;

      .is-off-right & {
        grid-column-start: 2;
        grid-column-end: 8;
      }
    }
  }
}


@include grid_has('rows');
@include grid_has('columns');
@include grid_item_spans('row');
@include grid_item_spans('column');
@include generate_gap('row');
@include generate_gap('column');
@include generate_direction('ltr');
@include generate_direction('rtl');
@include generate_place_self;
@include generate_order_classes('row');
@include generate_order_classes('column');
