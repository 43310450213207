@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.footer {
  background-color: color.$bg-grey;
  background-image: url('/images/template/wave-inverted.svg');
  background-size: auto 10px;
  background-repeat: repeat-x;

  &__newsletter {
    padding: spacing.$s18 spacing.$s2 spacing.$s8;

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s18 0 spacing.$s6;
    }
  }

  &__content {
    border-top: 1px solid var(--theme-color-base);
    padding: spacing.$s6 spacing.$s2;

    &:first-child {
      border-top: 0;
    }

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s6 0;
      border-top: 0;
    }

    &-block {
      a,
      p {
        display: flex;
        margin-bottom: spacing.$s2;
      }

      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        cursor: pointer;
        font-weight: 600;

        @media screen and (min-width: breakpoint.$lg) {
          margin-bottom: spacing.$s2;
          padding: 0;
          cursor: default;
          pointer-events: none;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          pointer-events: none;
        }

        i {
          font-size: font.$size-icon;

          @media screen and (min-width: breakpoint.$lg) {
            display: none;
          }
        }
      }

      &.is-first:last-child {
        .b2b & {
          order: -2;

          @media screen and (min-width: breakpoint.$lg) {
            order: initial;
          }
        }
      }
    }

    &-items {
      &.util--shown {
        padding-bottom: spacing.$s8;
      }

      @media screen and (min-width: breakpoint.$lg) {
        &.util--hidden {
          /* stylelint-disable-next-line declaration-no-important */
          display: block !important;
        }
      }
    }
  }

  &__contact {
    margin-top: spacing.$s6;

    a {
      display: flex;
      align-items: center;
      padding: 6px 0;

      @media screen and (min-width: breakpoint.$lg) {
        padding: 0;
      }
    }

    i {
      width: 24px;
      display: inline-block;
      text-align: center;
      margin-right: calc(#{spacing.$s2} * 0.5);
      font-size: font.$size-icon;
    }

    &-phone {
      display: flex;
      align-items: center;

      button {
        margin-top: -3px;
        margin-left: calc(#{spacing.$s2} * 0.5);
      }
    }

    &-details {
      font-size: font.$size-xxs;
      color: color.$base-60;
      margin: calc(#{spacing.$s2} * 0.5) 0 spacing.$s2;
      display: block;
    }
  }

  &__partners {
    padding: spacing.$s8 spacing.$s2;
    border-top: 1px solid var(--theme-color-base);

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s10 0;
      border-bottom: 1px solid var(--theme-color-base);
    }

    &-logos {
      display: flex;
      flex-wrap: wrap;
      gap: spacing.$s2 spacing.$s2;

      @media screen and (min-width: breakpoint.$lg) {
        gap: spacing.$s4 spacing.$s4;
      }

      img {
        width: auto;
      }

      &--small {
        justify-content: center;
        margin-bottom: spacing.$s6;

        @media screen and (min-width: breakpoint.$lg) {
          justify-content: flex-start;
          margin-bottom: 0;
        }

        img {
          height: 24px;
        }
      }

      &--large {
        justify-content: center;

        @media screen and (min-width: breakpoint.$lg) {
          justify-content: flex-end;
        }

        img {
          height: 54px;
        }
      }
    }

    &-badge {
      width: 80px;
      height: 48px;
      margin-top: spacing.$s6;

      &:empty {
        display: none;
      }

      &--google {
        position: relative;
        /* stylelint-disable-next-line declaration-no-important */
        width: 100px !important;
        /* stylelint-disable-next-line declaration-no-important */
        margin: 0 !important;
        iframe {
          /* stylelint-disable-next-line declaration-no-important */
          height: 54px !important;
          /* stylelint-disable-next-line declaration-no-important */
          top: 0 !important;
          /* stylelint-disable-next-line declaration-no-important */
          width: 100px !important;
        }
      }
    }

    & > .wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media screen and (min-width: breakpoint.$lg) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__bottom {
    padding: 0 0 spacing.$s10 0;
    border-bottom: 1px solid var(--theme-color-white);

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s6 0;
    }

    &-lists {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: breakpoint.$lg) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    a {
      font-size: font.$size-xxs;
      color: var(--theme-font-color);
      margin: 0 spacing.$s2 spacing.$s2;

      @media screen and (min-width: breakpoint.$lg) {
        margin-bottom: 0;
      }

      &:last-child {
        @media screen and (min-width: breakpoint.$lg) {
          margin-right: 0;
        }
      }

      &:first-child {
        @media screen and (min-width: breakpoint.$lg) {
          margin-left: 0;
        }
      }
    }
  }

  &__humanCheckoutKey{
    align-self: center;
    font-size: font.$size-xxs;
    color: var(--theme-font-color-light);
    margin-left: spacing.$s2;
  }

  &__copyright {
    text-align: center;

    @media screen and (min-width: breakpoint.$lg) {
      text-align: left;
    }
  }

  &__social {
    margin-bottom: spacing.$s6;

    @media screen and (min-width: breakpoint.$lg) {
      margin-left: auto;
      margin-bottom: 0;
    }
  }
}
