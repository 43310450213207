@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';

.blog-card {
  border: 1px solid var(--theme-color-base);

  .section--almond &,
  .section--grey & {
    background-color: var(--theme-color-white);
  }

  h2,
  h3 {
    margin-bottom: spacing.$s6;
  }

  time {
    color: color.$base-60;
    margin-bottom: spacing.$s10;
  }

  &__content {
    padding: spacing.$s6;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s8;
    }
  }

  &__meta {
    margin-bottom: spacing.$s10;
    color: color.$base-60;

    time {
      margin-bottom: 0;
    }

    span {
      margin: 0 spacing.$s2;
      color: var(--theme-font-color);
    }
  }

  p {
    color: color.$base-80;
    margin-bottom: spacing.$s10;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .button__stack {
    margin-top: auto;
  }
}
