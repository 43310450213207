@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.page-header {
  position: relative;
}

.page-heading {
  padding-left: spacing.$s2;
  padding-right: spacing.$s2;

  @media screen and (min-width: breakpoint.$md) {
    padding-left: 0;
    padding-right: 0;
  }

  &.is-centered {
    p {
      margin: spacing.$s6 auto 0;
    }
  }

  p,
  ul {
    margin: spacing.$s4 0 0;
    max-width: 800px;

    &:empty {
      display: none;
    }
  }

  &-commerce-title {
    font-size: 32px;
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: 0 !important;

    @media screen and (min-width: breakpoint.$md) {
      font-size: 44px;
    }
  }

  &-commerce-text {
    font-size: 17px;
  }
}

.page-description {
  overflow: hidden;
  word-break: break-word;
  padding-left: spacing.$s2;
  padding-right: spacing.$s2;
  margin-top: spacing.$s4;

  @media screen and (min-width: breakpoint.$md) {
    padding-left: 0;
    margin-top: spacing.$s4;
  }

  &-text {
    white-space: pre-line;
    line-height: 21px;

    @media screen and (min-width: breakpoint.$md) {
      max-width: 60%;
    }

    &:empty {
      margin-top: 0;
    }

    .page-description--closed & {
      // stylelint-disable-next-line value-no-vendor-prefix
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-box-pack: end;
      text-overflow: ellipsis;
    }

    .page-description--open & {
      display: block;
    }
  }

  &-manager {
    height: auto;
    max-width: 50%;
    overflow: visible;
    white-space: pre-line;
    word-break: normal;
  }

  &-read-more {
    color: var(--theme-font-color);
    line-height: 21px;
    height: 21px;
    display: none;
    margin-left: spacing.$s2;

    @media screen and (min-width: breakpoint.$md) {
      margin-left: 0;
    }

    &:hover {
      background-image: url('/images/template/wave-hover.svg');
      background-repeat: repeat-x;
      background-size: auto 5px;
      background-position: bottom;
    }
  }

  &.page-description--closed + .page-description-read-more {
    display: inline-block;
  }
}

// Fix for /nieuw page
.page-content-header {
  padding: spacing.$s18 0;
  text-align: center;

  @media screen and (min-width: breakpoint.$md) {
    padding: spacing.$s28 0 0;
  }

  .header-title {
    font-size: font.$size-lg;

    @media screen and (min-width: breakpoint.$md) {
      font-size: font.$size-xl;
    }
  }
}
