@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';
@use '../../mixin/image';

.section {
  $section: &;
  padding: spacing.$s18 0 0;

  &:last-child,
  &:last-of-type,
  &:only-child {
    padding-bottom: spacing.$s18;
  }

  &#previous-cadeau-viewer {
    padding-bottom: 0;
  }

  &--bg-green {
    background: color.$green-gradient;
  }

  &--bg-yellow {
    background: color.$yellow-gradient;
  }

  &--bg-red {
    background: color.$pink-gradient;
  }

  &--bg-blue {
    background: color.$blue-gradient;
  }

  &--bg-grey {
    background: var(--theme-color-base-light);

    &-wave {
      &:before {
        @include image.background-image-pseudo(
          $_background-image: '/images/template/wave-inverted.svg',
          $_background-image-height: 10px
        );
      }

      &:after {
        @include image.background-image-pseudo(
          $_background-image: '/images/template/wave-inverted.svg',
          $_background-image-height: 10px
        );
        transform: rotate(180deg);
      }
    }
  }

  &--flex {
    display: flex;
  }

  &--mt--xl {
    margin-top: spacing.$s18;

    @media screen and (min-width: breakpoint.$md) {
      margin-top: spacing.$s28;
    }
  }

  &--xs {
    padding-top: spacing.$s6;
  }

  &--sm {
    padding-top: spacing.$s6;

    @media screen and (min-width: breakpoint.$lg) {
      padding-top: spacing.$s8;
    }
  }

  &--md {
    padding-top: spacing.$s10;

    &.wave-top {
      margin-top: spacing.$s10;
    }
  }

  &--lg {
    padding: spacing.$s10 0 0;

    &:last-child,
    &:last-of-type,
    &:only-child {
      padding-bottom: spacing.$s10;
    }

    @media screen and (min-width: breakpoint.$md) {
      padding-top: spacing.$s18;

      &:last-child,
      &:last-of-type,
      &:only-child {
        padding-bottom: spacing.$s18;
      }
    }

    &.wave-top {
      margin-top: spacing.$s10;

      @media screen and (min-width: breakpoint.$md) {
        margin-top: spacing.$s18;
      }
    }

    &.wave-bottom {
      padding-bottom: spacing.$s10;

      @media screen and (min-width: breakpoint.$md) {
        padding-bottom: spacing.$s18;
      }
    }
  }

  &--xl {
    padding-top: spacing.$s18;

    &:last-child,
    &:last-of-type,
    &:only-child {
      padding-bottom: spacing.$s18;
    }

    @media screen and (min-width: breakpoint.$md) {
      padding-top: spacing.$s28;

      &:last-child,
      &:last-of-type,
      &:only-child {
        padding-bottom: spacing.$s28;
      }
    }

    &.wave-top {
      margin-top: spacing.$s18;

      @media screen and (min-width: breakpoint.$md) {
        margin-top: spacing.$s28;
      }
    }

    &.wave-bottom {
      padding-bottom: spacing.$s18;

      @media screen and (min-width: breakpoint.$md) {
        padding-bottom: spacing.$s28;
      }
    }
  }
}

.wave {
  background-image: url('/images/template/wave.svg');
  background-size: auto 10px;
  background-repeat: repeat-x;

  &-top {
    background-position: top;
  }

  &-bottom {
    background-position: bottom;
  }
}

// Exceptions
.hero + .section--almond,
.hero + .section--grey,
.breadcrumbs + .section--almond,
.breadcrumbs + .section--grey {
  margin-top: 0;
}

.section + .section.has-border-top {
  margin-top: spacing.$s28;

  @media screen and (min-width: breakpoint.$md) {
    margin-top: spacing.$s28;
  }
}

.section--heading + .section:not(.wave) {
  padding-top: spacing.$s8;
}

.section--breadcrumb + .section {
  padding-top: spacing.$s8;
}
