@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.text-component {
  padding-left: spacing.$s2;
  padding-right: spacing.$s2;

  @media screen and (min-width: breakpoint.$md) {
    padding-left: 0;
    padding-right: 0;
  }
}

.seo, .text-component {
  &__text {
    color: color.$base-80;
    white-space: pre-line;
    line-height: 1.3;

    > div {
      color: color.$base-80;
    }

    &:only-child {
      margin-bottom: 0;
    }

    h2 {
      margin: spacing.$s6 0;
      display: block;

      @media screen and (min-width: breakpoint.$md) {
        font-size: font.$size-lg - 2;
      }

      &:first-child {
        margin-top: 0;
        margin-bottom: spacing.$s6;
        font-size: font.$size-lg;

        @media screen and (min-width: breakpoint.$md) {
          font-size: font.$size-xl;
        }
      }

      & + h2 {
        margin: 0 0 spacing.$s6;
      }
    }

    h3, h4, h5 {
      margin: spacing.$s6 0 spacing.$s2;
    }

    p {
      margin-top: spacing.$s4;
    }

    ul,
    ol {
      margin-top: spacing.$s6;
      padding-left: spacing.$s6;

      li {
        list-style: disc;
        margin-bottom: spacing.$s2;

        @media screen and (min-width: breakpoint.$lg) {
          margin-left: spacing.$s6;
        }
      }
    }

    ol {
      li {
        list-style: decimal;
      }
    }

    a {
      text-decoration: underline;
    }
  }
}
