@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.newsletter {
  &-input {
    background-color: var(--theme-color-white);
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0 spacing.$s6;
    border-radius: 56px;
    border: 1px solid var(--theme-color-base);
    font-size: font.$size-base;

    &.dirty {
      padding: 0 spacing.$s6;
    }

    &:focus {
      padding: 0 spacing.$s6;
      background-color: var(--theme-color-white);
      border-color: var(--theme-color-base-semi-dark);
    }
  }
}
