@use '../../variable/color';
@use '../../variable/font';

.form-checkbox {
  position: relative;
  appearance: none;
  width: 18px;
  height: 18px;
  flex: 0 0 18px;
  border-radius: var(--theme-border-radius-xs);
  box-shadow: 0 0 0 1px var(--theme-color-base);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0 0 0 1px var(--theme-color-base-semi-dark);
  }

  &:checked {
    background-color: var(--theme-color-base-darker);
    box-shadow: 0 0 0 1px var(--theme-color-base-darker);

    &:hover {
      box-shadow: 0 0 0 1px var(--theme-color-base-darker);
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '\f00c';
      font-family: font.$icon;
      font-weight: 400;
      font-size: 13px;
      color: var(--theme-color-white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
