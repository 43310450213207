@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 460px;
  height: 100%;
  padding: 0 spacing.$s2;
  margin-bottom: spacing.$s8;

  @media screen and (min-width: breakpoint.$md) {
    margin-bottom: spacing.$s10;
    padding: 0 spacing.$s4;
  }

  &:last-child,
  &:only-child {
    margin-bottom: 0;
  }

  &__text {
    &--light {
      .h2, .h3, .h4 {
        color: var(--theme-font-color);
      }

      p, li {
        color: var(--theme-font-color-light);
      }
    }
  }

  .footer &,
  .block &,
  .checkout & {
    padding: 0 spacing.$s2;

    @media screen and (min-width: breakpoint.$md) {
      padding: 0;
    }
  }

  .is-centered & {
    margin: auto;
  }

  &.is-centered {
    margin: 0 auto spacing.$s8;

    @media screen and (min-width: breakpoint.$md) {
      margin: 0 auto spacing.$s10;
    }

    &:last-child,
    &:only-child {
      margin-bottom: 0;
    }
  }

  &--ng {
    height: auto;
  }

  // Temp hack
  #previous-cadeau-viewer &,
  #checkout-app & {
    margin-bottom: spacing.$s18;

    @media screen and (min-width: breakpoint.$md) {
      margin-bottom: spacing.$s28;
    }
  }

  &--fluid {
    max-width: 100%;
  }

  &--border-top {
    border-top: 1px solid var(--theme-color-base);
    padding-top: spacing.$s28;

    @media screen and (min-width: breakpoint.$md) {
      padding-top: spacing.$s28;
    }
  }

  .eyebrow {
    font-family: font.$primary;
    font-weight: 600;
    font-size: font.$size-xxs;
    text-transform: uppercase;
    color: var(--theme-color-secondary);
    margin-bottom: spacing.$s2;

    .b2b & {
      color: color.$sea;
    }

    @media screen and (min-width: breakpoint.$md) {
      margin-bottom: spacing.$s4;
    }

    & + h2,
    & + h3 {
      margin-top: 0;
    }
  }

  h2, .h2,
  h3, .h3,
  h4, .h4 {
    margin-top: spacing.$s10;

    .is-centered & {
      justify-content: center;
    }

    &.has-link {
      display: flex;
      align-items: baseline;

      a {
        display: none;

        @media screen and (min-width: breakpoint.$md) {
          display: inline-flex;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:only-child {
      padding-bottom: calc(#{spacing.$s2} * 0.5);
    }

    a,
    button {
      margin-left: auto;
      font-family: font.$primary;
      font-weight: font.$weight-regular;
    }
  }

  p,
  .p {
    margin-top: spacing.$s4;

    &:first-child {
      margin-top: 0;
    }
  }

  ul {
    margin-top: spacing.$s6;
  }
}
