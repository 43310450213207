@use '../../scss/settings/spacing';

@use '../tools/tools.layout';

.d-block {
  display: block;
}

.d-i-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}
