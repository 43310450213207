@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.form-error {
  margin: spacing.$s10 0;

  &:first-child {
    margin-top: 0;
  }

  &:empty {
    margin: 0;
  }

  li {
    padding: 0 spacing.$s2;
    display: flex;
    align-items: flex-start;
    margin-top: spacing.$s2;
    font-size: font.$size-xxs;

    &:first-child {
      margin-top: 0;
    }
  }

  i {
    font-size: font.$size-icon;
    margin-bottom: 0;
    margin-right: spacing.$s2;
    margin-top: -1px;
  }

  .error {
    color: var(--theme-color-error);

    i {
      color: var(--theme-color-error);
    }

    br {
      display: none;
    }
  }

  .success {
    color: var(--theme-color-primary);
  }
}

.form-control ~ .form-error {
  margin-top: calc(#{spacing.$s2} + 2px);
  margin-bottom: 0;

  &:empty {
    margin: 0;
  }
}

.form-validation {
  position: absolute;
  right: spacing.$s6;
  top: calc(50% - #{spacing.$s2});
  display: none;

  i {
    font-size: font.$size-icon;
    color: var(--theme-color-primary);
  }
}
