// Variable
@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.usp {
  display: flex;
  align-items: center;

  &-icon {
    width: 48px;
    height: 48px;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    margin-right: spacing.$s2;
    font-size: font.$size-icon;
  }

  p {
    font-size: font.$size-xxs;
  }
}
