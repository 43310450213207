// Variable
@use '../variable/breakpoint';
@use '../variable/font';
@use '../../stories/scss/settings/spacing';

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  font-family: font.$secondary;
  color: var(--theme-font-color);
  font-weight: font.$weight-regular;
  letter-spacing: -0.4px;

  &:empty {
    display: none;
  }
}

.h1,
h1 {
  font-size: font.$size-lg;

  @media screen and (min-width: breakpoint.$lg) {
    font-size: font.$size-xl;
  }
}

.h2,
h2 {
  font-size: font.$size-md;

  @media screen and (min-width: breakpoint.$md) {
    font-size: font.$size-lg;
  }
}

.h3,
h3 {
  font-size: font.$size-sm;

  @media screen and (min-width: breakpoint.$md) {
    font-size: font.$size-md;
  }

}

.h4,
h4 {
  font-size: font.$size-xs;

  @media screen and (min-width: breakpoint.$md) {
    font-size: font.$size-sm;
  }
}

.h5,
h5 {
  font-size: font.$size-xs - 2;

  @media screen and (min-width: breakpoint.$md) {
    font-size: font.$size-xs;
  }
}

.h6,
h6 {
  font-size: font.$size-base - 1;
  font-weight: font.$weight-regular;
  color: #171F0F52;

  @media screen and (min-width: breakpoint.$md) {
    font-size: font.$size-base;
  }
}

.p {
  margin-top: spacing.$s6;

  &:first-of-type {
    margin-top: spacing.$s6;

    @media screen and (min-width: breakpoint.$md) {
      margin-top: spacing.$s8;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  ul {
    list-style: disc;
    padding-left: spacing.$s6;
    margin-bottom: spacing.$s4;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

a {
  display: inline-block;
}
