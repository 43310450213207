@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.ratingBlock {
  margin-bottom: spacing.$s10;

  &-title {
    margin-bottom: spacing.$s10;
  }

  &__numbers {
    display: none;

    @media screen and (min-width: breakpoint.$md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__unhappy,
    &__happy {
      width: 26px;
      height: 26px;
    }

    &__rating {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &:hover {
        color: color.$base;
      }

      &--selected {
        background-color: color.$base;
        color: var(--theme-color-white);
      }
    }
  }

  &__select {
    display: block;

    @media screen and (min-width: breakpoint.$md) {
      display: none;
    }
  }

  &__response {
    display: flex;
    align-items: center;
    padding: spacing.$s10;
    background-color: color.$bg-grey;
    margin-bottom: spacing.$s10;
    min-height: 150px;

    &__rating {
      width: 48px;
      height: 48px;
      flex: 0 0 48px;
      line-height: 42px;
      font-size: 52px;
      text-align: center;
      margin-right: spacing.$s10;
      font-family: font.$secondary;
      font-weight: font.$weight-bold;
    }
  }
}

.npsGiftPreview {
  height: 250px;

  @media screen and (min-width: breakpoint.$md) {
    height: 345px;
  }
}
