@use 'sass:math';
@use '../settings/spacing';

$rules: [
  m  margin,
  ml margin-left,
  mt margin-top,
  mb margin-bottom,
  mr margin-right,
  p padding,
  pl padding-left,
  pt padding-top,
  pb padding-bottom,
  pr padding-right,
  ];

$spacings: [
  spacing.$s0,
  spacing.$s1,
  spacing.$s2,
  spacing.$s3,
  spacing.$s4,
  spacing.$s6,
  spacing.$s7,
  spacing.$s8,
  spacing.$s10,
  spacing.$s12,
  spacing.$s18,
  spacing.$s28,
  ];

@each $label, $prop in $rules {
  @each $value in  $spacings {
    // Ensure we strip the 'px'
    $unit : math.div($value, ($value * 0 + 4));
    .#{$label}-#{$unit} {
      #{$prop}: $value;
    }
  }
}

// Add an -auto version for all rules
@each $label, $prop in $rules {
  .#{$label}-auto {
    #{$prop}: auto;
  }
}
