// this will be converted to collapse component
.util {
  &__slide {
    transition: transform 0.3s ease;
    transform: none;

    &--slidedOutBottom {
      transform: translateY(200%);
    }
  }

  &__show-when-collapsed {
    display: none;
  }

  &__collapsible {
    overflow: hidden;

    @supports (--custom-property: 0) {
      max-height: var(--expanded-height, auto);
      transition: max-height 0.2s ease 0s;

      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }

    &--expanded {
      overflow: visible;
    }

    &--collapsed {
      // This is to hide elements that might stick out of the collapsed element such as tooltips
      visibility: hidden;
      overflow: hidden;
      max-height: 0;

      @supports (--custom-property: 0) {
        max-height: var(--collapsed-height, 0);
      }

      // there is no proper syntax to repeat the parent twice so we write it fully
      &.util__collapsible--partial {
        visibility: visible;
        // default value, override per use case
        max-height: 195px;

        @supports (--custom-property: 0) {
          --collapsed-height: 195px;
          max-height: var(--collapsed-height);
        }

        & > .util__show-when-collapsed{
          display: block;
        }
      }
    }
  }

  &__expandedContent::after {
    content: ' ';
    display: table;
    clear: both;
  }

  &--noTransition {
    /* stylelint-disable-next-line declaration-no-important */
    transition: none !important;
  }

  &--hidden {
    // We use !important here because display is a common property to use
    // and almost all selectors will be later and/or more specific than this one
    // The alternative would be to set this style directly on the elements

    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }

  &__noScroll {
    // We want to force vertical scrollbars even though we want to disable scrolling
    // this way the page won't flicker due to a resize, since there are probably already vertical scrollbars
    overflow-y: scroll;
    overflow-x: hidden;
    position: unset;
    left: 0;
    right: 0;

    // Optionally, you could detect you don't need scrollbars and remove them entirely
    &--noScrollbars {
      overflow: hidden;
    }
  }
}
