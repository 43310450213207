@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

// Checkout exception
.step__summary + .notification-box {
  margin-bottom: spacing.$s18;
}

.notification-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: spacing.$s6;
  margin-bottom: spacing.$s6;
  margin-top: spacing.$s6;
  border-radius: var(--theme-border-radius-sm);

  .checkout__discount & {
    margin-top: 0;
    border-radius: 0 0 var(--theme-border-radius-sm) var(--theme-border-radius-sm);
  }

  @media screen and (min-width: breakpoint.$md) {
    justify-content: space-between;
    flex-direction: row;
    padding: spacing.$s6;
    margin-bottom: spacing.$s10;
    flex-wrap: nowrap;

    .product-information-desktop & {
      padding: spacing.$s6;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--info {
    background-color: var(--theme-color-info-light);
    --NotificationBox-icon-color: var(--theme-color-info);
  }

  &--warning {
    background-color: var(--theme-color-warning-light);
    --NotificationBox-icon-color: var(--theme-color-warning);
  }

  &--success {
    background-color: var(--theme-color-success-light);
    --NotificationBox-icon-color: var(--theme-color-success);
  }

  &--error {
    background-color: var(--theme-color-error-light);
    --NotificationBox-icon-color: var(--theme-color-error);
  }

  &__message {
    display: flex;
    align-items: center;

    i {
      margin-right: spacing.$s4;
      font-size: 1.25em;
      color: var(--NotificationBox-icon-color);
    }

    span {
      font-size: font.$size-base;
      padding-top: 2px;
    }
  }

  &__check {
    margin-top: spacing.$s6;
    flex: 1;
    display: flex;

    button {
      margin-left: auto;
    }

    @media screen and (min-width: breakpoint.$md) {
      margin-top: 0;
    }
  }

  &__action {
    margin-top: spacing.$s6;

    @media screen and (min-width: breakpoint.$md) {
      margin-top: 0;
      margin-left: spacing.$s6;
    }
  }
}
