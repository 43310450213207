@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.my-surprise-cart {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button{
    position: relative;
  }

  &__amount {
    position: absolute;
    top: 12px;
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    font-size: 10px;
    font-family: font.$primary;
    background-color: var(--theme-color-white);
    color: var(--theme-color-secondary);
    border-radius: 50%;
  }
}
