@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';

.link {
  color: color.$base-80;
  transition: transition.$default color;

  &:hover {
    color: color.$base;
  }

  &--icon {
    display: inline-flex;
    align-items: center;

    i {
      margin-left: spacing.$s2;
      transition: transition.$default transform;
    }

    &:hover {
      i {
        transform: translate(3px, 0);
      }
    }
  }

  &--underline {
    position: relative;

    &:after {
      content: '';

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      border-bottom: 1px solid color.$base;
      transform: scaleX(0);
      transform-origin: 50% 50%;
      transition: transition.$default transform;
    }

    &:focus {
      position: relative;
      outline: 0;

      &:after {
        transform: scaleX(1);
      }
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  &--highlight {
    color: var(--theme-color-secondary);

    &:hover {
      color: var(--theme-color-secondary);
    }
  }
}
