@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/shadow';
@use '../../variable/transition';

.button {
  --height: 48px;
  padding: 0 spacing.$s6;
  height: var(--height);
  line-height: var(--height);
  border-radius: var(--height);
  font-size: font.$size-base;
  border: 0;
  text-align: center;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .notification-box & {
    font-size: font.$size-xxs;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
  }

  @media screen and (max-width: breakpoint.$md) {
    .hero & {
      padding: 0 20px;
    }
  }

  &--square {
    border-radius: var(--theme-border-radius-sm);
    width: 32px;
    padding: 0;
  }

  &--clean {
    width: 48px;
    border: 0;
    background-color: transparent;
    padding: 0;
  }

  &--ghost {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--theme-color-base);
    transition: transition.$default box-shadow;

    &:hover {
      box-shadow: inset 0 0 0 1px var(--theme-color-base-semi-dark);
    }

    .is-outline & {
      box-shadow: 0 0 0 1px var(--theme-color-base);

      &:hover {
        box-shadow: 0 0 0 1px var(--theme-color-base-semi-dark);
      }
    }
  }

  &--light {
    background-color: var(--theme-color-background);
    box-shadow: inset 0 0 0 1px var(--theme-color-base);
  }

  &--white {
    background-color: var(--theme-color-white);
    box-shadow: shadow.$drop-bottom;

    &:hover {
      background-color: var(--theme-color-white);
      box-shadow: shadow.$drop-dark-bottom;
    }
  }

  &--grey,
  &--yellow,
  &--blue,
  &--primary,
  &--secondary,
  &--happy-path {
    font-weight: font.$weight-600;
    transition: transition.$default background-color;
  }

  &--grey {
    background-color: color.$bg-grey;
    box-shadow: 0 0 0 1px color.$bg-grey;

    &:hover {
      background-color: var(--theme-color-base-darker);
      box-shadow: 0 0 0 1px var(--theme-color-base-darker);
    }
  }

  &--yellow {
    background-color: color.$yellow;
    box-shadow: 0 0 0 1px color.$yellow;

    &:hover {
      background-color: color.$yellow-dark;
      box-shadow: 0 0 0 1px color.$yellow-dark;
    }
  }

  &--blue {
    background-color: color.$blue;
    box-shadow: 0 0 0 1px color.$blue;
    color: var(--theme-color-white);

    &:hover {
      background-color: color.$blue-dark;
      box-shadow: 0 0 0 1px color.$blue-dark;
    }
  }

  &--round {
    padding: 0;
    width: 48px;
  }

  &--link {
    background-color: transparent;
    height: auto;
    padding: 0;
    border-radius: 0;
    transition: transition.$default color;

    &:hover {
      color: var(--theme-font-color-light);
    }

    .cart__item-actions & {
      font-size: font.$size-xxs;
      color: var(--theme-font-color);

      &:hover {
        color: var(--theme-font-color-light);
      }
    }

    .specifications-container & {
      span {
        padding-top: 1px;
      }
    }
  }

  &--primary {
    background-color: var(--theme-color-secondary);
    color: var(--theme-color-white);

    &:hover {
      color: var(--theme-color-white);
      background-color: var(--theme-color-secondary-dark);
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    user-select: none;
  }

  &--secondary {
    background-color: var(--theme-color-primary);
    color: var(--theme-color-white);

    &:hover {
      color: var(--theme-color-white);
      background-color: var(--theme-color-primary-dark);
    }
  }

  &--happy-path {
    background-color: var(--theme-color-primary);
    color: var(--theme-color-white);

    &:hover {
      color: var(--theme-color-white);
      background-color: var(--theme-color-primary-dark);
    }
  }

  &--sm {
    height: 32px;
  }

  &--xs {
    height: 40px;
    line-height: 40px;
    border-radius: 40px;

    &.button--clean {
      width: 40px;
    }
  }

  &--filter {
    background-color: color.$bg-grey;

    &-active {
      background-color: var(--theme-color-background);
      border: 1px solid var(--theme-color-base);
      height: 36px;
      border-radius: 36px;
      padding: 0 spacing.$s4;
      display: inline-flex;
      font-size: font.$size-xxs;
    }
  }

  &--facebook {
    background-color: #3B5998;
    color: var(--theme-color-white);

    &:hover {
      color: var(--theme-color-white);
    }
  }

  @media screen and (max-width: breakpoint.$md) {
    &--fluid {
      width: 100%;
    }
  }

  @media screen and (max-width: breakpoint.$lg) {
    &--fluid {
      .search-main & {
        width: 100%;
      }
    }
  }

  &--has-icon {
    i:not(:only-child) {
      &:first-child {
        margin-right: spacing.$s2;
      }
      &:last-child {
        margin-left: spacing.$s2;
      }
    }

    img {
      height: 16px;
      transition: transition.$default transform;

      &:first-child {
        margin-right: spacing.$s2;
      }

      &:last-child {
        margin-left: spacing.$s2;
      }
    }
  }

  &--split {
    justify-content: space-between;
  }
}

button.adyen-checkout__button {
  @extend .button;
  @extend .button--happy-path;
  width: auto;

  @media screen and (max-width: breakpoint.$md) {
    width: 100%;
  }

  &--pay {
    float: right;
  }
}
