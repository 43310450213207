$low-1: 1;
$low-2: 2;
$low-3: 3;
$low-4: 4;
$low-5: 5;

$medium-1: 40;
$medium-2: 41;
$medium-3: 42;
$medium-4: 43;
$medium-5: 44;

$top-1: 83;
$top-2: 84;
$top-3: 85;
$top-4: 86;
$top-5: 87;

$highest: 2147483647;
