@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.form-input {
  appearance: none;
  background-color: var(--theme-color-white);
  border: 1px solid var(--theme-color-base);
  border-radius: var(--theme-border-radius-sm);
  width: 100%;
  height: 54px;
  color: var(--theme-font-color);
  padding: 0 spacing.$s4;
  font-size: font.$size-base;

  &.input-transparent {
    background-color: transparent;
  }

  .has-warning & {
    border-color: rgb(from var(--theme-color-error) r g b / 0.6);
  }

  &[type='number'],
  &[type='date'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      right: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  &:hover,
  &:focus {
    border-color: var(--theme-color-base-semi-dark);
  }

  &:focus,
  &.dirty {
    padding-top: 16px;
  }

  // Hide default jQuery validate message
  & + .error,
  & + .success {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }

  &[disabled],
  &[readonly] {
    background-color: var(--theme-color-base);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
    background-color: var(--theme-color-base);

    &:focus {
      border-color: var(--theme-color-base);
    }
  }

  &.error {
    border-color: var(--theme-color-error);
  }

  &.no-label {
    height: 40px;

    &:focus,
    &.dirty {
      padding-top: 0;
    }
  }

  &.input-large {
    height: 56px;
  }
}
