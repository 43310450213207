// Variable
@use '../../variable/breakpoint';
@use '../../../stories/scss/settings/spacing';

// Mixin
@use '../../mixin/container';

.blog-page {
  .container {
    @include container.wrapper;

    /* stylelint-disable-next-line no-duplicate-selectors */
    & {
      color: var(--theme-font-color);
      padding-bottom: spacing.$s18;
    }

    @media screen and (min-width: breakpoint.$md) {
      padding-bottom: spacing.$s18;
    }

    center {
      margin-top: spacing.$s8;
    }

    a {
      text-decoration: underline;
    }

    > a:first-of-type {
      display: flex;
    }

    h1 {
      margin: spacing.$s18 0 spacing.$s4;
    }

    h2 {
      margin: spacing.$s8 0 spacing.$s4;
    }

    h3 {
      margin: spacing.$s8 0 spacing.$s4;
    }

    .product-list {
      margin-top: spacing.$s8;
      margin-bottom: spacing.$s8;

      a {
        text-decoration: none;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
    }
  }
}
