@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/breakpoint';

.form-chips {
  display: none;

  &:checked + &-label {
    background-color: var(--theme-color-primary);
    color: var(--theme-color-white);

    i {
      display: flex;
      margin-left: spacing.$s2;
      font-size: font.$size-icon;
    }
  }

  &-label {
    user-select: none;
    cursor: pointer;
    box-shadow: 0 0 0 1px var(--theme-color-base);
    line-height: 32px;
    border-radius: 30px;
    background-color: var(--theme-color-white);
    padding: 0 spacing.$s6;
    white-space: nowrap;
    transition: background-color 0.5s;
    display: flex;
    align-items: center;
    margin-bottom: spacing.$s2;
    margin-right: spacing.$s2;
    font-size: font.$size-base;

    @media screen and (min-width: breakpoint.$md) {
      padding: 4px spacing.$s6;
    }

    i {
      display: none;
    }
  }
}
