@mixin background-image-pseudo(
  $_background-image,
  $_background-display: block,
  $_background-image-width: 100%,
  $_background-image-height: 100%,
  $_background-repeat: repeat-x,
  $_background-size: contain,
){
  content: '';
  display: $_background-display;
  background-image: url($_background-image);
  width: $_background-image-width;
  height: $_background-image-height;
  background-repeat: $_background-repeat;
  background-size: $_background-size;
}
