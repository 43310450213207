@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/shadow';

.review {
  background-color: var(--theme-color-white);
  padding: spacing.$s6;
  border-radius: var(--theme-border-radius-sm);

  #productpage_reviews &,
  #productpage & {
    padding: spacing.$s6;
    background-color: color.$bg-grey;

    @media screen and (min-width: breakpoint.$md) {
      padding: spacing.$s8;
    }

    &:nth-child(n + 2) {
      margin-top: spacing.$s6;
    }
  }

  // With more review button
  &--last {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &-total {
      display: flex;
      align-items: center;
      margin-bottom: spacing.$s6;
    }
  }

  &-text {
    min-height: calc(20px * 4);
    margin-top: spacing.$s8;

    #productpage_reviews &,
    #productpage & {
      min-height: auto;
    }
  }

  &-response {
    margin-top: spacing.$s6;
    background-color: var(--theme-color-white);
    padding: spacing.$s6;
    border-radius: var(--theme-border-radius-md);
    box-shadow: shadow.$drop-bottom;
  }

  &-name {
    font-size: font.$size-xxs;
    margin-top: 8px;
    color: var(--theme-font-color-light);

    span {
      display: inline-block;
      margin: 0 calc(#{spacing.$s2} * 0.5);
    }
  }

  &__rating-stars {
    i {
      color: color.$yellow;
      margin-right: 1px;
    }
  }

  &__rating-text {
    font-size: font.$size-xxs;
    margin-left: spacing.$s2;
    margin-top: calc(#{spacing.$s2} * 0.5);
    font-weight: 600;
  }

  &__total-rating {
    width: 56px;
    height: 56px;
    font-family: font.$secondary;
    font-size: 26px;
    color: var(--theme-color-white);
    background-color: color.$base;
    border-radius: 50%;
    text-align: center;

    span {
      line-height: 52px;
    }
  }

  &__total-text {
    margin-bottom: spacing.$s6;
  }
}


