@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';

.form-radio {
  appearance: none;
  width: 14px;
  height: 14px;
  flex: 0 0 14px;
  box-shadow: 0 0 0 1px var(--theme-color-base);
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all transition.$default;

  &:checked {
    position: relative;
    background-color: var(--theme-color-base-darker);
    box-shadow: 0 0 0 1px var(--theme-color-base-darker);

    &:hover {
      box-shadow: 0 0 0 1px var(--theme-color-base-darker);
    }

    &:before {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      background-color: var(--theme-color-white);
      border-radius: 50%;
    }
  }
}

.form-radioGroup {
  display: flex;
  width: 100%;

  & > * {
    display: flex;
    flex: 1 auto;

    &:first-child {
      margin-right: spacing.$s2;
    }

    &:last-child {
      margin-left: spacing.$s2;
    }
  }

  &-item {
    background-color: var(--theme-color-white);
    border: 1px solid var(--theme-color-base);
    border-radius: var(--theme-border-radius-sm);
    width: 100%;
    height: 54px;
    color: var(--theme-font-color);
    padding: 0 spacing.$s6;
    font-size: font.$size-base;
    display: flex;
    align-items: center;
    transition: all transition.$default;

    &:hover {
      border-color: var(--theme-color-base-semi-dark);
    }

    label {
      line-height: 54px;
      padding-left: spacing.$s4;
      flex: 1;
      cursor: pointer;
    }
  }
}
