@use '../../variable/breakpoint';
@use '../../../stories/scss/settings/spacing';

.swiper.js-swiper-review {
  overflow: visible;
}

.swiper {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: breakpoint.$md) {
    flex-direction: column;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin-top: spacing.$s6;

    @media screen and (min-width: breakpoint.$md) {
      margin-top: 0;
      margin-bottom: spacing.$s10;
      position: absolute;
      bottom: 100%;
      right: 0;
    }
  }

  &-slide {
    height: auto;
    max-width: 251px;

    @media screen and (min-width: breakpoint.$lg) {
      max-width: 438px;
    }
  }

  &-next,
  &-prev {
    width: 48px;
    height: 48px;

    &.swiper-button-disabled {
      opacity: 0.6;
      visibility: visible;
      cursor: not-allowed;
    }

    .section--almond &,
    .section--grey & {
      box-shadow: 0 0 0 1px var(--theme-color-base);
    }
  }

  &-next {
    margin-left: spacing.$s4;

    &:not(.shadow-scroll &) {
      right: spacing.$s2;

      @media screen and (min-width: breakpoint.$md) {
        right: spacing.$s6;
      }
    }

    i {
      right: -2px;
    }
  }

  &-prev {
    &:not(.shadow-scroll &) {
      left: spacing.$s2;

      @media screen and (min-width: breakpoint.$md) {
        left: spacing.$s6;
      }
    }

    i {
      left: -2px;
    }
  }
}
