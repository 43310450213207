html {
  color: var(--theme-font-color);
  font-family: var(--theme-font-family-primary);
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
}

::selection, ::-moz-selection {
  background-color: var(--theme-color-secondary);
  color: var(--theme-color-white);
}

body {
  font-size: var(--theme-font-size-base);
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: var(--theme-font-color);

  &:hover {
    color: var(--theme-font-color);
  }
}


input,
select,
textarea {
  &:focus-visible {
    border: 1px solid var(--theme-color-base-dark);
  }
}

a,
button {
  &:focus-visible {
    box-shadow: 0 0 0 2px var(--theme-color-white), 0 0 0 3px var(--theme-color-base-dark);
  }
}
