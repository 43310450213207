:root, :root[theme=ysp] {
  --theme-color-primary: rgb(118, 137, 72);
  --theme-color-primary-dark: rgb(96, 112, 59);
  --theme-color-primary-light: rgba(118, 137, 72, 0.16);
  --theme-color-secondary: rgb(222, 137, 110);
  --theme-color-secondary-dark: rgb(196, 121, 98);
  --theme-color-secondary-light: rgb(251, 238, 234);
  --theme-color-white: rgb(255, 255, 255);
  --theme-color-base: rgba(23, 31, 15, 0.08);
  --theme-color-base-light: rgba(23, 31, 15, 0.04);
  --theme-color-base-semi-dark: rgba(23, 31, 15, 0.22);
  --theme-color-base-dark: rgba(23, 31, 15, 0.32);
  --theme-color-base-darker: rgb(127, 120, 112);
  --theme-color-background: rgb(252, 248, 244);
  --theme-color-background-dark: rgba(248, 244, 238);
  --theme-color-background-darker: rgb(245, 241, 237);

  --theme-color-accent: rgb(242, 175, 56);

  --theme-color-error: rgb(131, 58, 36);
  --theme-color-error-light: rgb(250, 239, 233);
  --theme-color-success: rgb(118, 137, 72);
  --theme-color-success-light: rgb(235, 237, 228);
  --theme-color-info: rgb(58, 121, 145);
  --theme-color-info-light: rgb(236, 238, 236);
  --theme-color-warning: rgb(196, 152, 98);
  --theme-color-warning-light: rgb(250, 242, 233);

  --theme-border-radius-xs: 4px;
  --theme-border-radius-sm: 8px;
  --theme-border-radius-md: 12px;
  --theme-border-radius-xl: 16px;
  --theme-element-size-sm: 32px;
  --theme-element-size-md: 48px;
  --theme-element-size-lg: 56px;

  --theme-element-color-primary: rgba(252, 248, 244, 1);
  --theme-element-color-secondary: rgba(252, 248, 244, 1);
  --theme-button-border: 1px solid rgba(23, 31, 15, 0.08);
  --theme-button-primay-box-shadow: none;
  --theme-button-font-weight: 600;
  --theme-button-font-weight-default: normal;
  --theme-heading-font-weight: normal;

  --theme-font-size-min: 11px;
  --theme-font-size-xxs: 13px;
  --theme-font-size-base: 15px;
  --theme-font-size-xs: 24px;
  --theme-font-size-sm: 26px;
  --theme-font-size-md: 30px;
  --theme-font-size-lg: 34px;
  --theme-font-size-xl: 38px;
  --theme-font-size-icon: 16px;
  --theme-font-icon-weight: normal;
  --theme-font-color: rgb(85, 76, 68);
  --theme-font-color-light: rgb(125, 118, 110);
  --theme-font-color-lighter: rgb(175, 170, 164);
  --theme-font-family-primary: Museo Sans, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  --theme-font-family-secondary: Garamond, Georgia, serif;

  --theme-shadow-soft: 0 3px 9px 0 rgba(23, 31, 15, 0.06), 0 1px 2px 0 rgba(23, 31, 15, 0.12);
  --theme-shadow-dark-top: 0 -4px 8px 0 rgba(23, 31, 15, 0.06), 0 -1px 1px 0 rgba(23, 31, 15, 0.04);

  --theme-logo-image: url('https://assets.yoursurprise.com/images/template/logo-ysp-2020.svg');
  --theme-logo-width: 156px;
  --theme-logo-height: 48px;
  --theme-dataform-header-close-border: var(--theme-button-border);
  --theme-dataform-header-color: var(--theme-font-color);
  --theme-dataform-header-background: transparent;
  --theme-dataform-icon-style: var(--fa-font-regular);
}
