@use '../../variable/breakpoint';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.form {
  &-title {
    font-size: font.$size-md;
    margin-bottom: spacing.$s6;

    @media screen and (min-width: breakpoint.$md) {
      margin-bottom: spacing.$s8;
    }
  }
}
