@use '../../variable/font';
@use '../../variable/transition';

.form-select {
  font-family: font.$primary;
  cursor: pointer;
  transition: transition.$default border;

  &:hover {
    border-color: var(--theme-color-base-semi-dark);
  }

  &:focus,
  &.dirty {
    padding-top: 16px;
  }

  &-wrapper {
    position: relative;
    width: 100%;
  }
}
