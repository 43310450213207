@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/shadow';

.block {
  border-radius: var(--theme-border-radius-sm);

  @media screen and (max-width: breakpoint.$md) {
    .wrapper--fluid & {
      border-radius: 0;
    }
  }

  &--fluid {
    margin-left: calc(#{spacing.$s2} * -1);
    margin-right: calc(#{spacing.$s2} * -1);
    border-radius: 0;

    @media screen and (min-width: breakpoint.$md) {
      margin-left: 0;
      margin-right: 0;
      border-radius: var(--theme-border-radius-sm);
    }
  }

  &--border {
    border: 1px solid var(--theme-color-base);

    &.block--fluid {
      border-left: 0;
      border-right: 0;
      border-bottom: 0;

      @media screen and (min-width: breakpoint.$md) {
        border-left: 1px solid var(--theme-color-base);
        border-right: 1px solid var(--theme-color-base);
        border-bottom: 1px solid var(--theme-color-base);
      }
    }
  }

  &--shadow {
    box-shadow: shadow.$drop-bottom;
  }

  &--boxed {
    padding: spacing.$s8 spacing.$s2;


    &-xs {
      padding: spacing.$s8 spacing.$s6;
    }

    @media screen and (min-width: breakpoint.$md) {
      padding: spacing.$s10;

      &-xs {
        padding: spacing.$s10;
      }

      &-lg {
        padding: spacing.$s18;
      }
    }

    &-y {
      padding: spacing.$s18 0;

      &.block--fluid {
        padding-left: spacing.$s6;
        padding-right: spacing.$s6;
      }

      &:first-of-type,
      &:first-child {
        padding-top: 0;
      }

      @media screen and (min-width: breakpoint.$md) {
        padding: spacing.$s28 0;

        &.block--fluid {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &--spaced {
    &--bottom {
      margin-bottom: spacing.$s10;
    }
  }

  &--16-9,
  &--square {
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: var(--theme-border-radius-sm);

      @media screen and (max-width: breakpoint.$md) {
        .wrapper--fluid & {
          border-radius: 0;
        }
      }
    }
  }

  &--16-9 {
    &:after {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }
  }

  &--square {
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  &--contain {
    img {
      object-fit: contain;
    }
  }

  // Colours
  &--grey {
    background-color: color.$bg-grey;
  }

  &--white {
    background-color: var(--theme-color-white);
  }

  // Exceptions
  &.has-border-top:first-child {
    border-top: 0;
  }

  .faq-page & {
    &:not(:first-child) {
      margin-top: spacing.$s6;

      @media screen and (min-width: breakpoint.$md) {
        margin-top: spacing.$s8;
      }
    }
  }
}
