@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.hero {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: spacing.$s8;
  border-radius: var(--theme-border-radius-xl);
  width: 100%;

  &--static {
    height: 240px;

    @media screen and (min-width: breakpoint.$lg) {
      height: 320px;
    }
  }

  &--large {
    height: 240px;

    @media screen and (min-width: breakpoint.$lg) {
      height: 400px;
    }
  }

  &--dynamic {
    padding: spacing.$s28 0;

    @media screen and (min-width: breakpoint.$md) {
      padding: spacing.$s28 0;
    }
  }

  &--half-vh,
  &--third-vh {
    min-height: 320px;
    margin-bottom: spacing.$s28;

    #about-us & {
      margin-bottom: 0;
    }

    @media screen and (min-width: breakpoint.$md) {
      min-height: calc(100vh / 2);
      margin-bottom: spacing.$s28;

      #about-us & {
        margin-bottom: 0;
      }
    }
  }

  &--third-vh {
    @media screen and (min-width: breakpoint.$md) {
      height: calc(100vh / 3);
    }
  }

  // Elements
  & > a,
  &__image {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, color.$base-60 0%, color.$base-30 100%);
    border-radius: var(--theme-border-radius-sm);

    .has-overlay & {
      display: flex;
    }

    @media screen and (min-width: breakpoint.$md) {
      background-image: linear-gradient(0deg, color.$base-40 0%, color.$base-20 100%);
    }
  }

  & > a {
    &[href='#'],
    &[href=''] {
      pointer-events: none;
    }
  }

  picture {
    width: 100%;
    height: 100%;
    display: flex;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: var(--theme-border-radius-sm);
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-left: spacing.$s6;
    padding-right: spacing.$s6;

    .hero--dynamic & {
      padding-bottom: 0;
    }

    .hero.has-overlay & {
      background-color: transparent;
    }

    .hero--half-vh & {
      background-color: transparent;
      margin: spacing.$s28 0;
    }

    @media screen and (min-width: breakpoint.$md) {
      width: 50%;
      background-color: transparent;
      padding-bottom: 0;
      align-items: flex-start;
      text-align: left;

      .is-placed-right & {
        margin-left: auto;
      }

      .is-placed-left & {
        margin-right: auto;
      }

      .is-placed-center & {
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
    }

    h1,
    h2 {
      font-size: font.$size-lg;
      letter-spacing: -0.1px;

      .hero.has-overlay & {
        color: var(--theme-color-white);
        text-shadow: -1px 1px 0 color.$base-40;
      }

      .is-placed-center & {
        text-align: center;
        width: 100%;
      }

      &:only-child {
        margin-bottom: 0;
      }

      @media screen and (min-width: breakpoint.$lg) {
        font-size: font.$size-xl;
      }
    }

    p {
      font-size: 16px;
      margin-top: spacing.$s4;

      &:empty {
        margin-top: 0;
      }

      .hero.has-overlay & {
        color: var(--theme-color-white);
        text-shadow: -1px 1px 0 color.$base-40;
      }

      .is-placed-center & {
        text-align: center;
      }

      @media screen and (min-width: breakpoint.$md) {
        font-size: 18px;
        line-height: 26px;
      }

      @media screen and (min-width: breakpoint.$xl) {
        margin-top: spacing.$s4;
      }
    }
  }
}
