@use "../variable/color";
@use "../../stories/scss/settings/spacing";

:root {
  --spacing-base: #{spacing.$s2};
  --spacing-default: #{spacing.$s4};
  --spacing-xs: #{spacing.$s6};
  --spacing-sm: #{spacing.$s8};
  --spacing-md: #{spacing.$s10};
  --spacing-lg: #{spacing.$s18};
  --spacing-xl: #{spacing.$s28};

  --color-base: #{color.$base};
  --color-base-rgb: 33, 32, 31;
  --color-yellow-light: #{color.$yellow-light};
  --color-yellow: #{color.$yellow};
  --color-yellow-dark: #{color.$yellow-dark};
  --color-yellow-darker: #{color.$yellow-darker};
  --color-blue-lighter: #{color.$blue-lighter};
  --color-blue: #{color.$blue};
  --color-blue-dark: #{color.$blue-dark};
  --color-sea: #{color.$sea};
}
