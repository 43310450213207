@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.filter {
  padding: spacing.$s6 0;
  border-bottom: 1px solid var(--theme-color-base);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  &-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-list {
    margin-top: spacing.$s6;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;

    label {
      flex-grow: 1;
      cursor: pointer;
      font-size: font.$size-base;
    }

    &-check {
      display: flex;
      align-items: center;
      margin-right: 12px;

      input[type='checkbox'] {
        bottom: 2px;
      }
    }

    &-amount {
      font-size: font.$size-xxs;
      margin-left: spacing.$s2;
      color: var(--theme-font-color-lighter);
    }
  }

  &-active {
    margin-top: spacing.$s2;

    &:empty {
      display: none;
    }

    & > * {
      margin-right: spacing.$s2;
      margin-top: spacing.$s2;
    }
  }
}
