@use '../mixin/container';
@use '../variable/breakpoint';
@use '../../stories/scss/settings/spacing';

.u-h--auto {
  height: auto;
}

.u-w--full {
  width: 100%;
}

.u-hide,
.hidden
{
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.clear {
  clear: both;
}

.has-border-top {
  border-top: 1px solid var(--theme-color-base);
}

.has-border-bottom {
  border-bottom: 1px solid var(--theme-color-base);
}

.u-scrollable-x {
  @include container.h-scrollable();

  &--mobile {
    @media screen and (max-width: breakpoint.$md) {
      @include container.h-scrollable();
    }
  }
}

.wrapper {
  @include container.wrapper;

  &--centered {
    margin: 0 auto;
  }

  &--fluid {
    width: 100%;

    @media screen and (max-width: breakpoint.$md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--md {
    max-width: container.$container-md;
    padding-left: spacing.$s4;
    padding-right: spacing.$s4;
  }

  &--mobile {
    &--md {
      padding-left: spacing.$s4;
      padding-right: spacing.$s4;

      @media screen and (min-width: breakpoint.$md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.u-br {
  &--sm {
    border-radius: var(--theme-border-radius-sm);
  }
}

.expanded-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
