@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';

.product-subcategory {
  $category: &;
  border: 1px solid var(--theme-color-base);
  padding: 0 spacing.$s6;
  line-height: 48px;
  border-radius: 48px;
  display: flex;
  flex-wrap: nowrap;

  &:hover {
    i {
      transform: scale(-1) translate(-2px, 0);
    }
  }

  &-discount {
    display: none;
    color: var(--theme-color-secondary-light);
    border-left: 1px solid var(--theme-color-base);
    margin-left: spacing.$s6;
    padding-left: spacing.$s6;
  }

  &-label {
    display: flex;
    align-items: center;
    white-space: nowrap;

    i {
      transform: scale(-1);
      margin-left: spacing.$s2;
      font-size: font.$size-icon;
      transition: transition.$default transform;
    }
  }

  &:first-child {
    #{$category}-discount {
      display: inline-block;
    }
  }

  &-group {
    position: relative;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    padding: spacing.$s6 spacing.$s4;
    -ms-overflow-style: none; // for Internet Explorer, Edge
    scrollbar-width: none; // for Firefox
    margin-left: calc(#{spacing.$s2} * -1);
    margin-right: calc(#{spacing.$s2} * -1);

    &::-webkit-scrollbar {
      display: none; // for Chrome, Safari, and Opera
    }

    &:empty {
      display: none;
    }

    &--wrap {
      flex-wrap: wrap;
    }

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s8 0;
      margin-left: 0;
      margin-right: 0;
    }

    .product-category-group[data-top-product-list-group] & {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
