@use '../../../scss/variable/breakpoint';
@use '../../../scss/variable/font';
@use '../../../scss/mixin/typography';

.is-centered {
  text-align: center;

  @media screen and (max-width: breakpoint.$md) {
    &--md {
      text-align: center;
    }
  }
}

// Text align utility classes
.ta {
  &-l {
    text-align: left;
  }
  &-c {
    text-align: center;
  }
  &-r {
    text-align: right;
  }
}

// Text size utility classes
.ts {
  &-xxs {
    font-size: var(--theme-font-size-xxs);
  }

  &-base {
    font-size: var(--theme-font-size-base);
  }

  &-xs {
    font-size: var(--theme-font-size-xs);
  }

  &-md {
    font-size: var(--theme-font-size-md);
  }
}

.lh {
  &--normal {
    line-height: normal;
  }
}

.bold {
  font-weight: font.$weight-600;
}

.ellipses {
  @include typography.typography-ellipses(1);
}
