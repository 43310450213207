@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.drawer {
  width: 100%;
  height: 100%;
  background-color: var(--theme-color-background);
  margin-left: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 0 1px var(--theme-color-base);

  &-dynamic-height {
    height: auto;
    top: unset;
  }

  @media screen and (min-width: breakpoint.$md) {
    width: 420px;
  }

  &-header {
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 var(--theme-color-base);
    padding: spacing.$s2 spacing.$s4;

    @media screen and (min-width: breakpoint.$md) {
      padding: spacing.$s4;
    }

    &-title {
      padding-bottom: calc(#{spacing.$s2} * 0.5);
    }

    &-button {
      margin-left: auto;
      margin-right: -12px;
    }
  }

  &-content {
    height: 100%;
    overflow-y: auto;
    padding: spacing.$s6 spacing.$s4;

    @supports (scrollbar-gutter: stable both-edges) {
      scrollbar-gutter: stable both-edges;
    }
  }

  &-footer {
    box-shadow: 0 -1px 0 var(--theme-color-base);
    padding: spacing.$s4;
    display: flex;
    flex-direction: column;
    position: sticky;
    bottom: 0;
    background-color: var(--theme-color-background);

    @media screen and (min-width: breakpoint.$md) {
      padding: spacing.$s4 spacing.$s6;
    }

    & > * {
      flex-grow: 1;

      &:first-child {
        margin-right: spacing.$s4;
      }

      &:only-child {
        margin-right: 0;
      }
    }
  }
}
