@use '../../variable/breakpoint';
@use '../../../stories/scss/settings/spacing';

.about-us {
  &-review {
    @media screen and (min-width: breakpoint.$md) {
      display: flex;
    }

    img {
      opacity: 0.05;
      width: 64px;
      margin-left: calc(#{spacing.$s6} * -1);

      @media screen and (min-width: breakpoint.$md) {
        margin-right: spacing.$s18;
        flex: 0 0 auto;
        width: 72px;
        margin-top: -124px;
        margin-left: 0;
      }
    }

    h2 {
      flex: 1;
      font-size: 32px;
      margin-top: calc(#{spacing.$s6} * -1);
      letter-spacing: -0.2px;

      @media screen and (min-width: breakpoint.$md) {
        flex: 1;
        font-size: 42px;
        margin-top: 32px;
        margin-left: calc(#{spacing.$s18} * -1);
      }
    }
  }

  &-image-strip {
    margin-top: spacing.$s6;
    position: relative;
    display: flex;
    height: auto;
    overflow-x: auto;

    & > * {
      flex: 0 0 75%;
      display: flex;
    }

    @media screen and (min-width: breakpoint.$md) {
      margin-top: spacing.$s10;
      width: 100%;
      overflow-x: hidden;

      & > * {
        flex: 1;
      }
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}
