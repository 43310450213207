@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';

.form {
  &-notification {
    background-color: color.$bg-grey;
    padding: spacing.$s6;
    flex-grow: 1;
    font-size: 12px;
    margin-top: spacing.$s2;
  }
}
