@use '../../../scss/variable/color';
@use '../../../scss/../stories/scss/settings/spacing';

:root {
  --toastify-icon-color-success: var(--theme-color-success) !important;
  --toastify-icon-color-error: var(--theme-color-error) !important;
  --toastify-icon-color-info: var(--theme-color-info) !important;
  --toastify-icon-color-warning: var(--theme-color-warning) !important;
}

/* stylelint-disable */
@media only screen and (max-width : 480px) {
  .notifications {
    &.Toastify__toast-container {
      left: spacing.$s2;
      right: spacing.$s2;
      width: auto;
    }
    &.Toastify__toast-container--top-left, 
    &.Toastify__toast-container--top-center, 
    &.Toastify__toast-container--top-right {
      top: spacing.$s2;
    }
    &.Toastify__toast-container--bottom-left, 
    &.Toastify__toast-container--bottom-center, 
    &.Toastify__toast-container--bottom-right {
      bottom: spacing.$s2;
    }
    &.Toastify__toast-container--rtl {
      right: spacing.$s2;
    }
  }
}

.notifications {
  &.Toastify__toast-container {
    .Toastify__toast {
      border-radius: spacing.$s2;
      font-family: inherit;
      padding: spacing.$s6;

      &-theme--dark {
        background-color: color.$base-80;
        backdrop-filter: blur(10px);
      }
    }
  }
}
