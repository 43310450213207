@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/transition';

.pagination {
  margin-top: spacing.$s6;

  @media screen and (min-width: breakpoint.$md) {
    margin-top: spacing.$s8;
  }

  ul {
    list-style: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      margin: 0 spacing.$s2;

      a,
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 32px;
        height: 32px;
        color: var(--theme-font-color);
        border-radius: 50%;
        border: 1px solid transparent;
        background-color: transparent;
        transition: transition.$default all;
        font-size: font.$size-base;

        &:hover {
          color: color.$base;
          border: 1px solid var(--theme-color-base);
        }

        &.next,
        &.prev {
          padding: 0 spacing.$s6;
          border: 0;
          cursor: pointer;
          text-align: center;
          white-space: nowrap;
          font-family: font.$primary;
          background-color: var(--theme-color-background);
          box-shadow: 0 0 0 1px var(--theme-color-base);
          height: 48px;
          width: auto;
          line-height: 48px;
          border-radius: 48px;

          @media screen and (max-width: breakpoint.$md) {
            display: none;
          }
        }

        &.pagination__item--active {
          background-color: color.$bg-grey;
        }
      }
    }
  }
}
