// Variable
@use '../../variable/breakpoint';
@use '../../../stories/scss/settings/spacing';

.button__stack {
  display: flex;
  align-items: flex-start;

  &.is-centered {
    justify-content: center;

    @media screen and (max-width: breakpoint.$md) {
      &--md {
        justify-content: center;
      }
    }
  }

  &.is-stacked {
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: spacing.$s4;
    }

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }

  &.is-row {
    flex-direction: row;

    &.is-spaced {
      justify-content: space-between;
    }

    & > *:not(:last-child) {
      margin-top: 0;
      margin-right: spacing.$s2;

      @media screen and (min-width: breakpoint.$lg) {
        margin-right: spacing.$s4;
      }
    }
  }

  .form & {
    margin-top: 0;
  }

  .is-centered & {
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: breakpoint.$lg) {
    flex-direction: row;

    &--down {
      margin-top: auto;
    }

    & > *:not(:last-child) {
      margin-right: spacing.$s4;
      margin-top: 0;
    }
  }

  &.is-fluid {
    width: 100%;

    & > * {
      flex: 1;
      width: 100%;
    }

    &.cart__close {
      & > * {
        &:first-child {
          flex: 0;
        }

        &:last-child {
          flex: 1;
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: breakpoint.$xs) {
    &.is-fluid--xs {
      width: 100%;

      & > * {
        flex: 1;
        width: 100%;
      }
    }

    &.is-stacked--xs {
      flex-direction: column;

      & > *:not(:first-child) {
        margin-top: spacing.$s4;
      }

      & > *:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
