@use '../../../variable/breakpoint';
@use '../../../variable/color';
@use '../../../../stories/scss/settings/spacing';
@use '../../../variable/transition';

.menu {
  transform: translate(-100%, 0);
  overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: var(--theme-color-background);

  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.1, 0, 0.1, 1);

  .wrapper {
    padding: 0;

    @media screen and (min-width: breakpoint.$lg) {
      pointer-events: none;
      padding: 0 spacing.$s6;
    }

    @media screen and (min-width: breakpoint.$xl) {
      padding: 0 spacing.$s10;
    }
  }

  @media screen and (min-width: breakpoint.$lg) {
    display: block;

    position: static;

    background-color: transparent;
    box-shadow: none;

    width: 100%;

    transform: none;
    transition: none;

    &.hover-active {
      .wrapper {
        pointer-events: auto;
      }
    }
  }

  &__backdrop {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color.$base-40;

    opacity: 0;
    visibility: hidden;

    transition: transition.$default opacity;

    @media screen and (min-width: breakpoint.$lg) {
      display: none;
    }
  }

  &.is-open {
    overflow-y: auto;
    transform: translate(0);

    & + .menu__backdrop {
      opacity: 1;
      visibility: visible;
    }
  }

  &.no-scroll {
    overflow: hidden;
  }
}
