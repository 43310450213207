@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.form-textarea {
  height: 200px;
  font-family: font.$primary;

  &--small {
    min-height: 100px;
    height: 100px;
  }

  &--no-resize {
    resize: none;
  }

  &--ver-resize {
    resize: vertical;
  }

  &--hor-resize {
    resize: horizontal;
  }

  &:focus,
  &.dirty {
    padding-top: spacing.$s8;
  }
}
