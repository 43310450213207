@use '../../../variable/breakpoint';

.menu-burger {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: breakpoint.$lg) {
    display: none;
  }
}
