@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';

.reviewRating {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 spacing.$s2;

  @media screen and (min-width: breakpoint.$md) {
    justify-content: space-between;
    padding: 0 spacing.$s4;
    flex-direction: row;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: spacing.$s8;

    @media screen and (min-width: breakpoint.$md) {
      align-items: flex-end;
      margin-bottom: 0;
    }
  }

  &__stars {
    display: flex;
    align-items: center;
    margin-bottom: spacing.$s2;

    i {
      color: color.$yellow;
    }
  }

  &__rating {
    margin-left: spacing.$s2;
  }
}

.progressBar {
  display: flex;
  margin-bottom: spacing.$s2;
  align-items: center;

  &__stars {
    display: flex;
    flex-grow: 1;
    flex-basis: 20%;
    margin-right: spacing.$s2;
  }

  &__outer {
    display: flex;
    background-color: color.$bg-grey;
    width: 100%;
    height: 4px;
  }

  &__inner {
    background-color: color.$base;
    height: 100%;
  }

  &__total {
    display: flex;
    flex-basis: 10%;
    justify-content: flex-end;
    margin-left: spacing.$s2;
  }
}
