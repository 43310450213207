@use '../../../variable/breakpoint';
@use '../../../variable/color';
@use '../../../variable/font';
@use '../../../../stories/scss/settings/spacing';
@use '../../../variable/transition';
@use '../../../variable/components/header';
@use '../../../mixin/container';

.sticky-trigger {
  position: absolute;
  z-index: -1;
  top: 94px;
  width: 100%;
  height: header.$mobileHeaderHeight;
  pointer-events: none;
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--theme-color-background);
  border-bottom: 1px solid var(--theme-color-base);
  max-height: header.$desktopHeaderHeight;
  transition: top 0.5s cubic-bezier(0.4,0,0.2,1);

  &--checkout {
    position: static;
  }

  &-main {
    @include container.wrapper;

    /* stylelint-disable-next-line no-duplicate-selectors */
    & {
      display: flex;
      flex-wrap: wrap;
      min-height: 50px;
      padding: spacing.$s3 spacing.$s2;
      transition: padding transition.$default;
    }

    @media screen and (min-width: breakpoint.$min) {
      padding-left: spacing.$s4;
      padding-right: spacing.$s4;
    }

    @media screen and (min-width: breakpoint.$md) {
      padding-left: spacing.$s6;
      padding-right: spacing.$s6;
    }

    @media screen and (min-width: breakpoint.$xl) {
      padding-left: spacing.$s10;
      padding-right: spacing.$s10;
    }

    .has-sticky & {
      padding-bottom: 12px;
    }

    .header--checkout & {
      align-items: center;
      padding: spacing.$s4;

      @media screen and (min-width: breakpoint.$md) {
        padding-left: spacing.$s6;
        padding-right: spacing.$s6;
      }

      @media screen and (min-width: breakpoint.$xl) {
        padding-left: spacing.$s10;
        padding-right: spacing.$s10;
      }
    }

    &__menu {
      @media screen and (min-width: breakpoint.$lg) {
        display: none;
      }
    }

    &__logo {
      display: flex;
      flex: 1 0 auto;
      max-height: 32px;
      margin-left: spacing.$s2;

      @media screen and (min-width: breakpoint.$min) {
        max-height: 40px;
      }

      @media screen and (min-width: breakpoint.$md) {
        margin-left: spacing.$s4;
      }

      .header--checkout & {
        margin-left: 0;
      }

      @media screen and (min-width: breakpoint.$lg) {
        flex: 0 0 24%;
        max-height: 100%;
        justify-content: flex-start;
        margin-left: 0;
        align-items: center;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 78px;
      }
    }

    &__search {
      flex-wrap: nowrap;
      order: 3;
      width: 100%;
      margin-top: 12px;
      height: 100%;
      max-height: 200px;
      transition: all transition.$default;

      .has-sticky & {
        max-height: 0;
        opacity: 0;
        margin-top: 0;
        pointer-events: none;
      }

      .has-sticky.util__noScroll.util__noScroll--noScrollbars:not(.menu-open) & {
        max-height: 100%;
        opacity: 1;
        transition: none;
        pointer-events: auto;
      }

      @media screen and (min-width: breakpoint.$lg) {
        flex: 0 1 52%;
        display: flex;
        align-items: center;
        margin-top: 0;
      }

      .search-main {
        @media screen and (min-width: breakpoint.$lg) {
          flex-grow: 1;
        }
      }
    }

    &__account {
      display: flex;
      margin-left: auto;

      @media screen and (min-width: breakpoint.$lg) {
        order: 3;
        flex: 0 0 24%;
        justify-content: flex-end;
        align-items: center;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 78px;
      }

      // Button style for each icon
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: transparent;
        color: var(--theme-font-color);
        font-size: font.$size-base;
        border: 0;
        padding: 0;

        width: 32px;
        height: 32px;

        position: relative;
        cursor: pointer;

        @media screen and (min-width: breakpoint.$min) {
          width: 40px;
          height: 40px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
