@use '../../variable/breakpoint';
@use '../../../stories/scss/settings/spacing';

.form-control {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0 100%;

  .newsletter & {
    align-items: flex-start;
  }

  .form-row--fluid & {
    flex: auto;
    margin-top: 0;
  }

  @media screen and (max-width: breakpoint.$md) {
    & + .form-control {
      margin-top: spacing.$s6;

      .form-row--flex &,
      .form-row--fluid & {
        margin-top: 0;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &-action {
    position: absolute;
    right: spacing.$s2;
    top: 50%;
    transform: translate(0, -50%);

    &.is-no-click {
      pointer-events: none;
    }
  }
}
