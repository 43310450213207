@mixin typography-ellipses(
  $_typography-ellipses__line-clamp: 2
) {
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-pack: end;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  overflow-wrap: anywhere;
  -webkit-line-clamp: $_typography-ellipses__line-clamp;
}
