@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../variable/transition';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/shadow';
@use '../../mixin/typography';

.product-box {
  $product: &;
  position: relative;

  a {
    display: flex;
    flex-direction: column;

    .product-list--horizontal & {
      flex-direction: row;
    }
  }

  &:hover {
    #{$product}__image {
      box-shadow: shadow.$drop-dark-bottom;
    }
  }

  &__image {
    position: relative;
    padding: spacing.$s6 0;
    background-color: var(--theme-color-white);
    border-radius: var(--theme-border-radius-sm);
    box-shadow: shadow.$drop-bottom;
    transition: transition.$default box-shadow;

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s8 0;
    }

    .product-list--horizontal & {
      padding: spacing.$s4 0;
    }

    picture {
      display: flex;

      .product-list--horizontal & {
        width: 100px;
        height: 100px;
        flex: 0 0 100px;

        @media screen and (min-width: breakpoint.$lg) {
          padding: 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @media screen and (min-width: breakpoint.$lg) {
          .product-list--horizontal & {
            position: static;
          }
        }
      }
    }
  }

  &__info {
    padding: spacing.$s4 spacing.$s2 0;

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s6 spacing.$s4 0;
    }

    .product-list--horizontal & {
      padding: spacing.$s4;

      @media screen and (min-width: breakpoint.$lg) {
        padding: 0 0 0 spacing.$s4;
      }
    }

    &-title {
      @include typography.typography-ellipses(2);
      font-family: font.$primary;
      font-size: font.$size-base;
      margin-bottom: spacing.$s2;
      display: block;

      @media screen and (min-width: breakpoint.$lg) {
        .product-list--horizontal & {
          margin-bottom: calc(#{spacing.$s2} * 0.5);
        }
      }

      @media screen and (min-width: breakpoint.$lg) {
        margin-bottom: spacing.$s2;
      }
    }

    &-rating {
      margin-bottom: spacing.$s6;
      display: flex;
      align-items: center;

      .product-list--horizontal & {
        margin-bottom: spacing.$s2;
      }

      i {
        margin-right: 1px;
        font-size: font.$size-base;

        &.product-box__rating-star--empty {
          color: var(--theme-font-color-lighter);
        }
      }

      span {
        color: var(--theme-font-color-light);
        font-size: 12px;
        margin-left: calc(#{spacing.$s2} * 0.5);
        margin-top: 3px;
      }
    }

    &-price {
      color: var(--theme-font-color-light);
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .product-list--horizontal & {
        display: block;
      }

      &-discount {
        margin-right: spacing.$s2;
        color: var(--theme-font-color-lighter);
        text-decoration: line-through;
      }
    }

    &-types {
      color: var(--theme-font-color-lighter);
      font-size: 12px;
      margin-top: spacing.$s2;
    }
  }
}
