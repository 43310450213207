@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/shadow';

.product-category {
  position: relative;
  border-radius: var(--theme-border-radius-xl);

  .product-category-group & {
    margin-left: spacing.$s2;

    &:first-child {
      margin-left: 0;
    }
  }

  a {
    width: 100%;
    text-align: center;
  }

  picture {
    position: relative;
    display: block;
    background-color: var(--theme-color-white);
    border-radius: spacing.$s2;
    overflow: hidden;
    box-shadow: shadow.$drop-bottom;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &-title {
    margin-top: spacing.$s4;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-font-color);

    @media screen and (min-width: breakpoint.$md) {
      margin-top: spacing.$s6;
    }
  }
}
